import React, { useEffect, useState, useCallback, useRef } from 'react'
import classNames from 'classnames'
import Avatar from '../../../../common/element/Avatar'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Dropdown from '../../../../common/element/Dropdown'
import Badge from '../../../../common/element/Badge'
import Tooltip from '../../../../common/element/Tooltip'
import ScrollBar from '../../../../common/element/ScrollBar'
import { GetAllAdminNotifications } from '../../../../actions/Admin';
import { Link } from 'react-router-dom'
import io from 'socket.io-client';
import useTwColorByName from '../../../../utils/hooks/useTwColorByName'
import acronym from '../../../../utils/acronym'


const notificationHeight = 'h-96'
// const imagePath = 'assets/img/avatars/'

// const GeneratedAvatar = ({ target }) => {
//     const color = useTwColorByName()
//     return (
//         <Avatar shape="circle" className={`${color(target)}`}>
//             {acronym(target)}
//         </Avatar>
//     )
// }

const NotificationToggle = ({ className, dot, notificationCount }) => {
    return (
        <div className={classNames('relative text-2xl notification', className)}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.189 17.5a3.32 3.32 0 0 0 2.205.833 3.32 3.32 0 0 0 2.205-.833m2.795-10.833a5 5 0 0 0-10 0c0 2.575-.65 4.338-1.375 5.504-.613.984-.919 1.476-.907 1.613.012.152.044.21.167.3.11.083.609.083 1.606.083h11.018c.997 0 1.495 0 1.606-.082.122-.091.155-.15.167-.301.011-.137-.295-.63-.907-1.613-.725-1.166-1.375-2.93-1.375-5.504z" stroke="#5786CC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {dot && notificationCount > 0 && (
                <span className="absolute -top-1 -right-2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                    {notificationCount}
                </span>
            )}
        </div>
    );
};

const Notification = ({ className }) => {

    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState(0)
    const [notificationList, setNotificationList] = useState([]);
    const [unreadNotification, setUnreadNotification] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const scrollContainerRef = useRef(null)
    const [scrollThreshold, setScrollThreshold] = useState(168)
    const [limit, setLimit] = useState(15)
    const [socketRef, setSocketRef] = useState(null);
    const [loading, setLoading] = useState(false);

    const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
    const user_id = loginData?.userName?.user_id;
    const workerName = `${loginData?.userName?.first_name || ''} ${loginData?.userName?.last_name || ''}`;
    // const trading_name = loginData?.trading_name ? loginData?.trading_name : ""
    const userRole = "worker";
    const is_worker = true;
    // const user_id = loginData?.user_data?.user_id ? loginData?.user_data?.user_id : "";
    let socket;

    const connectSocket = useCallback(() => {
        if (!socket) {
            socket = io('https://conserve.speckledigital.com', {
                query: {
                    user_id: user_id,
                    userType: userRole,
                    worker_name: workerName,
                    is_worker: is_worker,
                    // trading_name: trading_name, //
                },
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
            });

            // socket.on('connect', () => {
            //     console.log('Connected to socket server');
            // });

            // socket.on('connect_error', (error) => {
            //     console.error('Connection Error:', error);
            // });

            // socket.on('error', (error) => {
            //     console.error('Socket Error:', error);
            // });

            socket?.emit('userLoggedIn', {
                user_id: user_id,
                userType: userRole,
                worker_name: workerName,
            });
            socket.on('notification', (notification) => {
                setNotificationList((prev) => [notification?.notificationData, ...prev]);
                setUnreadNotification(true);
                setNotificationCount((prevCount) => prevCount + 1);
            });
            setSocketRef(socket);
        }
    }, [user_id, userRole]);

    useEffect(() => {
        connectSocket();
        return () => {
            if (socket) {
                socket.off('notification');
                socket.disconnect();
                socket = null;
            }
        };
    }, [connectSocket]);

    const onNotificationOpen = useCallback(() => {
        if (notificationList.length === 0) {
            setLoading(true);
            setLoading(false);
        }
        setOpenNotifications(true)
    }, [notificationList]);

    const onMarkAsRead = useCallback(
        (notification_id) => {
            setNotificationList((prevList) => {
                const updatedList = prevList.map((item) => {
                    if (item.notification_id === notification_id) {
                        return { ...item, is_read: true };
                    }
                    return item;
                });
                setNotificationCount((prevCount) => {
                    const newCount = prevCount - 1;
                    setUnreadNotification(newCount > 0);
                    return newCount;
                });
                socketRef?.emit("markNotificationAsRead", { notification_id });
                return updatedList;
            });
        },
        [socketRef] // Added notificationCount as a dependency
    );

    const GetAllNotifications = useCallback(() => {
        const payload = { limit: limit, role_name: userRole, user_id: user_id, is_worker: is_worker };
        setIsFetching(true);
        dispatch(GetAllAdminNotifications(payload)).then((res) => {
            setIsFetching(false);
            if (res.success === true) {
                const arrayData = res.data.rows;
                setTotalCount(res?.data?.count);
                if (arrayData) {
                    setNotificationList(arrayData);
                    setNotificationCount(res?.data?.unreadCount);
                    setUnreadNotification(res?.data?.unreadCount > 0 ? true : false
                        // arrayData.some((item) => !item.is_read)
                    );
                }
            }
        });
    }, [userRole, user_id, dispatch, limit]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollContainer = scrollContainerRef.current;
            if (
                scrollContainer &&
                scrollContainer.scrollTop >= scrollThreshold &&
                !isFetching &&
                notificationList.length < totalCount
            ) {
                setLimit((prevLimit) => prevLimit + 15);
                setScrollThreshold((prevThreshold) => prevThreshold + 168);
            }
        };
        const scrollContainer = scrollContainerRef.current;
        scrollContainer?.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll);
        };
    }, [isFetching, notificationList.length, totalCount, scrollThreshold, openNotifications]);

    useEffect(() => {
        GetAllNotifications();
    }, [limit]);

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const startOfWeek = moment().startOf('week');
    const startOfMonth = moment().startOf('month');
    const startOfYear = moment().startOf('year');

    const groupedNotifications = notificationList.reduce(
        (acc, notification) => {
            const createdDate = moment(notification.created_date);

            if (createdDate.isSame(today, 'd')) {
                acc.today.push(notification);
            } else if (createdDate.isSame(yesterday, 'd')) {
                acc.yesterday.push(notification);
            } else if (createdDate.isSameOrAfter(startOfWeek)) {
                acc.thisWeek.push(notification);
            } else if (createdDate.isSameOrAfter(startOfMonth)) {
                acc.thisMonth.push(notification);
            } else if (createdDate.isSameOrAfter(startOfYear)) {
                acc.thisYear.push(notification);
            } else {
                acc.earlier.push(notification);
            }
            return acc;
        },
        { today: [], yesterday: [], thisWeek: [], thisMonth: [], thisYear: [], earlier: [] }
    );

    return (
        <Dropdown
            renderTitle={
                <NotificationToggle
                    dot={unreadNotification}
                    className={className}
                    notificationCount={notificationCount}
                />
            }
            menuClass="p-0 rounded-xl bottom-end min-w-[504px] md:min-w-[504px]"
            onOpen={onNotificationOpen}
        >
            <Dropdown.Item variant="header">
                <div className="border-b border-gray-200 dark:border-gray-600 py-4 px-6 flex items-center justify-between">
                    <h6 className="text-xl font-semibold text-gray-900">Notifications</h6>
                    <Tooltip title="Mark all as read">
                        <button
                            className="text-blue-700 font-semibold hover:text-gray-600"
                            onClick={() => {
                                const allRead = notificationList.map((item) => ({
                                    ...item,
                                    is_read: true,
                                }));
                                setNotificationList(allRead);
                                setUnreadNotification(false);
                                setNotificationCount(0);
                                allRead.forEach((item) =>
                                    socketRef?.emit('markNotificationAsRead', { notification_id: item.notification_id })
                                );
                            }}
                        >
                            Mark all as read
                        </button>
                    </Tooltip>
                </div>
            </Dropdown.Item>
            <div ref={scrollContainerRef} className={classNames('overflow-y-auto notification-list', notificationHeight)}>
                {loading ? (
                    <div className="text-center p-4">Loading...</div>
                ) : notificationList.length === 0 ? (
                    <div className="text-center p-4">No Data Available!</div>
                ) : (
                    <>
                        {groupedNotifications.today.length > 0 && (
                            <>
                                <h5 className="text-blue-700 font-bold px-6 py-2 mt-2">Today</h5>
                                <ul className="border-b pb-2">
                                    {groupedNotifications.today.map((notif) => (
                                        <li key={notif.notification_id}>
                                            <Link
                                                className={`flex justify-between py-1.5 px-6 hover:bg-blue-50 ${notif.is_read ? '' : 'font-bold'}`}
                                                to="#"
                                                onClick={() => !notif.is_read && onMarkAsRead(notif.notification_id)}
                                            >
                                                {notif?.is_read === true ? (
                                                    <div className="" width="10" height="10">

                                                    </div>
                                                ) : (
                                                    <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                    </svg>
                                                )}
                                                <Tooltip title={notif.message}>
                                                    <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                </Tooltip>
                                                <span className="text-gray-400 pl-2">
                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {groupedNotifications.yesterday.length > 0 && (
                            <>
                                <h5 className="text-blue-700 font-bold px-6 py-2 mt-2">Yesterday</h5>
                                <ul className="border-b pb-2">
                                    {groupedNotifications.yesterday.map((notif) => (
                                        <li key={notif.notification_id}>
                                            <Link
                                                className={`flex justify-between py-1.5 px-6 hover:bg-blue-50 ${notif.is_read ? '' : 'font-bold'}`}
                                                to="#"
                                                onClick={() => !notif.is_read && onMarkAsRead(notif.notification_id)}
                                            >
                                                {notif?.is_read === true ? (
                                                    <div className="" width="10" height="10">

                                                    </div>
                                                ) : (
                                                    <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                    </svg>
                                                )}
                                                <Tooltip title={notif.message}>
                                                    <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                </Tooltip>
                                                <span className="text-gray-400 pl-2">
                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {groupedNotifications.thisWeek.length > 0 && (
                            <>
                                <h5 className="text-blue-700 font-bold px-6 py-2 mt-2">This week</h5>
                                <ul className="border-b pb-2">
                                    {groupedNotifications.thisWeek.map((notif) => (
                                        <li key={notif.notification_id}>
                                            <Link
                                                className={`flex justify-between py-1.5 px-6 hover:bg-blue-50 ${notif.is_read ? '' : 'font-bold'}`}
                                                to="#"
                                                onClick={() => !notif.is_read && onMarkAsRead(notif.notification_id)}
                                            >
                                                {notif?.is_read === true ? (
                                                    <div className="" width="10" height="10">

                                                    </div>
                                                ) : (
                                                    <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                    </svg>
                                                )}
                                                <Tooltip title={notif.message}>
                                                    <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                </Tooltip>
                                                <span className="text-gray-400 pl-2">
                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {groupedNotifications.thisMonth.length > 0 && (
                            <>
                                <h5 className="text-blue-700 font-bold px-6 py-2 mt-2">This month</h5>
                                <ul className="border-b pb-2">
                                    {groupedNotifications.thisMonth.map((notif) => (
                                        <li key={notif.notification_id}>
                                            <Link
                                                className={`flex justify-between py-1.5 px-6 hover:bg-blue-50 ${notif.is_read ? '' : 'font-bold'}`}
                                                to="#"
                                                onClick={() => !notif.is_read && onMarkAsRead(notif.notification_id)}
                                            >
                                                {notif?.is_read === true ? (
                                                    <div className="" width="10" height="10">

                                                    </div>
                                                ) : (
                                                    <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                    </svg>
                                                )}
                                                <Tooltip title={notif.message}>
                                                    <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                </Tooltip>
                                                <span className="text-gray-400 pl-2">
                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {groupedNotifications.thisYear.length > 0 && (
                            <>
                                <h5 className="text-blue-700 font-bold px-6 py-2 mt-2">This year</h5>
                                <ul className="border-b pb-2">
                                    {groupedNotifications.thisYear.map((notif) => (
                                        <li key={notif.notification_id}>
                                            <Link
                                                className={`flex justify-between py-1.5 px-6 hover:bg-blue-50 ${notif.is_read ? '' : 'font-bold'}`}
                                                to="#"
                                                onClick={() => !notif.is_read && onMarkAsRead(notif.notification_id)}
                                            >
                                                {notif?.is_read === true ? (
                                                    <div className="" width="10" height="10">

                                                    </div>
                                                ) : (
                                                    <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                    </svg>
                                                )}
                                                <Tooltip title={notif.message}>
                                                    <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                </Tooltip>
                                                <span className="text-gray-400 pl-2">
                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </>)
                }
            </div>
            {isFetching && (
                <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-600"></div>
                </div>
            )}
            {isFetching == false && (
                <Dropdown.Item variant="header">
                    <div className="flex justify-center border-t border-gray-200 dark:border-gray-600 px-4 py-2">
                        <div className="font-bold cursor-pointer text-sm text-gray-400">
                            The End
                        </div>
                    </div>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
export default Notification
