import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../../../../../../common/element/Table";
import Badge from "../../../../../../../common/element/Badge";
import Tooltip from "../../../../../../../common/element/Tooltip";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import ViewInduction from "./SpecificSite/SiteInductions/ViewInduction";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import moment from "moment";
import BtnPrimaryLight from "../../../../../../../common/element/Buttons/primaryLightBtn";

import { GetSiteInductionAndModulesbyIdForClient } from "../../../../../../../actions/client-portal/AllSiteApi";
import { DeleteSiteInduction } from "../../../../../../../actions/client-portal/AllSiteApi";
import ThreeDotLoader from "../../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import { RestSiteInductionDetailForm } from "../../../../../../../redux/classes/Admin-Client/SiteInductionDetailForm"
import { ResetSiteInductionModuleData } from "../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { EditSiteInduction } from "../../../../../../../redux/classes/Admin-Client/EditSiteInductionSlice";
import { ResetEditSiteInduction } from "../../../../../../../redux/classes/Admin-Client/EditSiteInductionSlice";
import { siteInductionDetailForm } from "../../../../../../../redux/classes/Admin-Client/SiteInductionDetailForm";
import { AddSiteInductionModuleData } from "../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { refetchAllInductionsDataSite } from "../../../../../../../redux/classes/Admin-Client/refetchAllInductionsSite";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";

const SiteInductionList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  // const { siteId } = useParams();
  const { Tr, Th, Td, THead, TBody } = Table;
  let sites_permissions;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    sites_permissions = permissionData.sites_write
  }
  // if (storedData !== null) {
  // }


  // Add days to the validSDays
  const validSDays = moment(props?.siteData?.induction_valid_days?.created_date).add(
    props?.siteData?.induction_valid_days,
    "days"
  );


  const getAllState = useSelector((state) => state);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [checkEditdata, SetcheckEditdata] = useState(true);

  const [inductionOpen, setInductionOpen] = useState(false);
  const [siteInductionId, setSiteInductionId] = useState(null);
  const [deleteDiolog, setdeleteDiolog] = useState(false);
  useEffect(() => {
    setIsOpen(props?.veiwInduction);
    if (props?.veiwInduction) {
      SetcheckEditdata(true)
    }
  }, [props?.veiwInduction]);

  const openDialog = (site_induction_id) => {
    SetcheckEditdata(true);
    // dispatch(RestSiteInductionDetailForm())
    dispatch(ResetEditSiteInduction());
    dispatch(ResetSiteInductionModuleData());
    dispatch(GetSiteInductionAndModulesbyIdForClient(site_induction_id)).then(
      (response) => {
        if (response?.success) {
          dispatch(
            siteInductionDetailForm({
              site_id: response?.data?.site_id,
              site_ind_name: response?.data?.site_ind_name,
              site_ind_desc: response?.data?.site_ind_desc,
              site_induction_id: response?.data?.site_induction_id,
              site_ind_status: response?.data?.site_ind_status,
              // comp_doc_id_data: response?.data?.site_ind_comp_docs.map(
              //   (docs) => docs?.compliance_doc.compliance_doc_name
              // ),
              // induction changes pending
              site_ind_doctypes: response?.data?.site_ind_doctypes,
              // master_setting_id_data: response?.data?.site_ind_industries.map(
              //   (industry) => industry.master_setting.meta_value_one
              // ),
              master_setting_id_data: response?.data.site_ind_industries?.length
                ? response?.data?.site_ind_industries.map(industry => industry?.master_setting?.meta_value_one)
                : [],
            })
          );
          response?.data?.site_ind_modules.map((item) => {
            dispatch(AddSiteInductionModuleData({
              // index: {
              site_induction_id: item?.site_ind_module_id,
              module_name: item?.module_data?.module_name,
              content_info: item?.module_data?.content_info,
              module_desc: item?.module_data?.module_desc,
              content_link_title: item?.module_data?.content_link_title,
              content_info_type: item?.module_data?.content_info_type,
              module_type: item?.module_data?.module_type,
              module_ques_data: item?.module_data?.module_ques_data,
              // is_module_mandatory: true,
              // site_induction_file : [],
              module_id: item?.module_id,
              pdf_page_range: item?.module_data?.pdf_page_range,
              site_ind_module_id: item?.site_ind_module_id,
            }
            ));
            // dispatch(AddSiteInductionModuleData(item));
          });
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      }
    );
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
    dispatch(ResetSiteInductionModuleData());
    // dispatch(RestSiteInductionDetailForm())
    dispatch(ResetEditSiteInduction());
    SetcheckEditdata(false);
  };

  const onDeleteDialogClose = () => {
    if (!buttonDisabled) {
      setdeleteDiolog(false)
    }
  }

  const onConfirmDelete = async () => {
    setButtonDisabled(true);
    try {
      // DeleteSiteInduction replace this API  and change data according to requirements (site_induction_id)
      const payload = { site_induction_id: siteInductionId?.site_induction_id, moduleIds: siteInductionId?.moduleIds , site_name:props?.siteData?.site_name}
      const response = await dispatch(DeleteSiteInduction(payload,ipAddress));
      if (response?.success) {
        setButtonDisabled(false)
        onDeleteDialogClose()
        setSiteInductionId(null)
        props?.fetchData()
        // apply 
      } else {
        console.error("Failed to delete site inductons:", response.message);
      }
    } catch (error) {
      console.error("Error deleting site inductons:", error);
    } finally {
      setButtonDisabled(false)
    }
  };


  const inductionCptOpen = () => {
    // setInductionOpen(true);
    // setIsOpen(false);
  };

  const inductionCptClose = (e) => {
    setInductionOpen(false);
    dispatch(ResetSiteInductionModuleData());
  };

  const handleEditinduction = (site_induction_id, e) => {

    if (e == "EditModule") {
      SetcheckEditdata(true);
      // dispatch(RestSiteInductionDetailForm())
      dispatch(ResetEditSiteInduction());
      dispatch(ResetSiteInductionModuleData());
      dispatch(
        GetSiteInductionAndModulesbyIdForClient(site_induction_id)
      ).then((response) => {
        if (response?.success) {

          dispatch(
            siteInductionDetailForm({
              site_id: response?.data?.site_id,
              site_ind_name: response?.data?.site_ind_name,
              site_ind_desc: response?.data?.site_ind_desc,
              site_induction_id: response?.data?.site_induction_id,
              site_ind_status: response?.data?.site_ind_status,
              // document_type_id_data: response?.data?.site_ind_doc_types,


              // comp_doc_id_data: response?.data?.site_ind_comp_docs,

              // induction changes pending
              site_ind_doctypes: response?.data?.site_ind_doctypes,

              // .map((docs) => docs?.compliance_doc.compliance_doc_name),
              master_setting_id_data: response?.data?.site_ind_industries
              // document_type_id_data: response?.data?.site_ind_doc_types.map(
              //   (docs) => docs?.document_type_id
              // ),
              // master_setting_id_data: response?.data?.site_ind_industries.map(
              //   (industry) => industry.master_setting_id
              // ),
            })
          );
          response?.data?.site_ind_modules.map((item, index) => {
            dispatch(AddSiteInductionModuleData({
              // index: {
              site_induction_id: item?.site_ind_module_id,
              module_name: item?.module_data?.module_name,
              content_info: item?.module_data?.content_info,
              module_desc: item?.module_data?.module_desc,
              content_link_title: item?.module_data?.content_link_title,
              content_info_type: item?.module_data?.content_info_type,
              module_type: item?.module_data?.module_type,
              module_ques_data: item?.module_data?.module_ques_data,
              // is_module_mandatory: true,
              // site_induction_file : [],
              module_id: item?.module_id,
              pdf_page_range: item?.module_data?.pdf_page_range,
              site_ind_module_id: item?.site_ind_module_id,

              // }
            }
              // item
            ));
          });
          // localStorage.setItem("SiteIndDocTypeData", JSON.stringify(response?.data?.site_ind_doc_types))
          // localStorage.setItem("SiteIndIndustryTypeData", JSON.stringify(response?.data?.site_ind_industries))
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      });
      setIsOpen(true);
      dispatch(EditSiteInduction(true));
      setIsOpen(false);
    } else {
      SetcheckEditdata(true);
      dispatch(ResetEditSiteInduction());
      dispatch(EditSiteInduction(true));
      // SetcheckEditdata(false);
      setIsOpen(false);
    }
  };

  const closeViewInduction = () => {
    dispatch(ResetEditSiteInduction());
    SetcheckEditdata(false);
    setIsOpen(false);
  };

  const handleDeleteinduction = (item) => {
    setSiteInductionId(item)
    setdeleteDiolog(true)
  }

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("site_ind_name")}
                >
                  Induction name
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "site_ind_name" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("module_count")}
                >
                  No. of Modules
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "module_count" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("created_date")}
                >
                  Induction Start Date
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "created_date" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">
                  Induction End Date
                  {/* <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg> */}
                </span>
              </Th>
              <Th className="w-36">Status</Th>
              <Th className="w-28"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.data?.rows?.map((item, index) => {
              return (
                <Tr>
                  <Td>{item?.site_ind_name}</Td>
                  <Td>{item?.module_count}</Td>
                  <Td>{moment(item?.created_date).format("DD/MM/YYYY")}</Td>
                  <Td>{moment(validSDays).format("DD/MM/YYYY")}</Td>
                  <Td>
                    {item?.site_ind_status == "draft" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg font-normal"
                        content={"Draft"}
                        innerClass="bg-blue-50  text-blue-800"
                      />
                    )}
                    {item?.site_ind_status == "archived" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg font-normal"
                        content={"archived"}
                        innerClass="text-gray-700 bg-gray-100"
                      />
                    )}
                    {item?.site_ind_status == "active" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg font-normal"
                        content={"Active"}
                        innerClass="bg-success-100 text-success-700"
                      />
                    )}
                  </Td>
                  <Td>
                    <div className="flex items-center justify-end gap-4">
                      <Tooltip title="View" placement="top">
                        <button
                          disabled={props?.sitePermission}
                          onClick={() => openDialog(item?.site_induction_id)}
                        >
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                              stroke="#667085"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                              stroke="#667085"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>

                      <Tooltip title="Edit" placement="top">
                        <button
                          disabled={props?.sitePermission}
                          onClick={() =>
                            handleEditinduction(
                              item?.site_induction_id,
                              "EditModule"
                            )
                          }
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.667 8.333 12.333 5M2.75 17.917l2.82-.314c.345-.038.517-.057.678-.11.143-.046.279-.11.404-.194.142-.093.264-.215.51-.46L18.166 5.832A2.357 2.357 0 1 0 14.833 2.5L3.828 13.505c-.245.245-.368.368-.46.51a1.667 1.667 0 0 0-.195.403c-.052.161-.071.334-.11.678l-.313 2.82z"
                              stroke="#344054"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>

                      <Tooltip title="Delete" placement="top">
                        <button disabled={props?.sitePermission} onClick={() => handleDeleteinduction(item)
                        }>
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                              stroke="#667085"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>
      {checkEditdata && (
        <Dialog
          isOpen={dialogIsOpen}
          onClose={onDialogClose}
          onRequestClose={onDialogClose}
          bodyOpenClassName="overflow-hidden"
          className="lg-dialog"
        >
          <div className="flex border-b pb-5 px-6 pt-6 items-center">
            <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
              <img src="/assets/icons-svg/book-open.svg" alt="" />
            </span>
            <div>
              <h5 className="text-gray-900 text-base font-semibold">
                {getAllState.siteInductionDetailData.site_ind_name}
              </h5>
            </div>
          </div>
          <ViewInduction closeViewInductionModule={closeViewInduction} handleEditinductionModule={handleEditinduction} />



        </Dialog>
      )}
      <Dialog
        isOpen={inductionOpen}
        onClose={inductionCptClose}
        onRequestClose={inductionCptClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Congrats! Induction completed successfully.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={inductionCptClose}>Close</BtnLight>
        </div>
      </Dialog>

      {/* Add Dialog Delete */}
      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete induction?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} className="mr-1.5" onClick={onDeleteDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} className="ml-1.5" onClick={onConfirmDelete}>
            {buttonDisabled ? <ThreeDotLoader /> : "Delete"}
          </BtnPrimary>
        </div>
      </Dialog>

    </React.Fragment>
  );
};
export default SiteInductionList;