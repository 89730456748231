import React from "react";
import Tabs from "../../../../../../../common/element/Tabs";
import ClientDetails from "./ClientDetails";
import Users from "./User";
import Accreditations from "./Accreditation";
import PdfData from "../../../../../../providerPortal/pages/Clients/pdf/Index";
import { useSelector } from "react-redux";
const ClientSettings = (props) => {
  const { TabNav, TabList, TabContent } = Tabs;
  const { SpecificProviderData } = useSelector((state) => state);

  return (
    <React.Fragment>
      <Tabs defaultValue="tab1" variant="pill">
        <div className="p-3">
          <div className="bg-blue-25 border border-gray-100 p-1 rounded-lg">
            <TabList className="flex">
              <TabNav className="flex-1" value="tab1">
                Provider Details
              </TabNav>
              <TabNav className="flex-1" value="tab2">
                WHS Contact
              </TabNav>
              <TabNav className="flex-1" value="tab3">
                Users
              </TabNav>
              <TabNav value="tab4" className="flex-1">
                Compliance certificate
              </TabNav>
            </TabList>
          </div>
        </div>
        <div className="pl-6">
          <TabContent value="tab1">
            {" "}
            <ClientDetails userSettingHide={props.userSettingHide} />
          </TabContent>
          <TabContent value="tab2">
            <Accreditations userSettingHide={props.userSettingHide} />
          </TabContent>
          <TabContent value="tab3">
            <Users userSettingHide={props.userSettingHide} />{" "}
          </TabContent>
          <TabContent value="tab4">
            <PdfData
              userSettingHide={props.userSettingHide}
              PdfData={SpecificProviderData}
              type="adminprovider"
            />
          </TabContent>
        </div>
      </Tabs>
    </React.Fragment>
  );
};
export default ClientSettings;
