import React, { useEffect } from "react";
import Tabs from "../../../../../common/element/Tabs";
import ContractorName from "./ContractorName";
import Documents from "../SpecificContractorTabs/Documents";
import Clients from "../SpecificContractorTabs/Clients";
import Workers from "../SpecificContractorTabs/Workers";
import Contacts from "../SpecificContractorTabs/Contacts";
import Overview from "../SpecificContractorTabs/Overview";
import { Invoices } from "../SpecificContractorTabs/Invoices";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Reports } from "../SpecificContractorTabs/Reports";
const { TabNav, TabList, TabContent } = Tabs;
const SpecificContractor = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Provider",
          url: "/admin/provider/view-all-provider",
          home: "/admin/provider/dashboard" 
        },
        {
          label: "Annette Souter",
          url: `/admin/provider/view-all-provider/specific-provider/${encodeURIComponent(id)}`,
        },
      ])
    );
  }, []);
  
  return (
    <React.Fragment>
      <ContractorName />
      <Tabs defaultValue="tab1" variant="pill" className="contractorTabsList">
        <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
          <TabList>
            <TabNav value="tab1">Overview</TabNav>
            <TabNav value="tab2">Documents</TabNav>
            <TabNav value="tab3">Clients</TabNav>
            <TabNav value="tab4">Workers</TabNav>
            <TabNav value="tab5">Invoices</TabNav>
            <TabNav value="tab6">Reports</TabNav>
            <TabNav value="tab7">Contacts</TabNav>
          </TabList>
        </div>
        <div className="">
          <TabContent value="tab1">
            <Overview />
          </TabContent>
          <TabContent value="tab2">
            <Documents />
          </TabContent>
          <TabContent value="tab3">
            <Clients />
          </TabContent>
          <TabContent value="tab4">
            <Workers />
          </TabContent>
          <TabContent value="tab5"><Invoices/></TabContent>
          <TabContent value="tab6"><Reports/></TabContent>
          <TabContent value="tab7">
            <Contacts />
          </TabContent>
        </div>
      </Tabs>
    </React.Fragment>
  );
};

export default SpecificContractor;
