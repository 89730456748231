import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAdminSpecificContractorSlice";
export const GetProviderOtherDetails = (apiData, type) => async (dispatch) => {
  /*** API Call Action ***/
  console.log("workertype::", apiData);
  try {
    //   dispatch(fetchData())
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/${
        type == "worker" ? "worker" : "admin/worker"
      }/GetProviderOtherDetails`,
      params: { provider_org_id: apiData?.provider_org_id },
    });
    return response.data;
    //   dispatch(fetchDataSuccess(response.data))
  } catch (error) {
    return error.message;
    //   dispatch(fetchDataError(error.message))
  }
};
