// import React, { useState } from "react";
// import Card from "../../common/element/Card";
// // import Heading from "../../../../common/element/Heading";
// import { Link } from "react-router-dom";
// import BtnLight from "../../common/element/Buttons/btnLight";
// import BtnPrimary from "../../common/element/Buttons/btnPrimary";
// import Segment from "../../common/element/Segment";
// import Radio from "../../common/element/Radio";
// import Dialog from "../../common/element/Dialog";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// const PaymentMethod = () => {
//   const [docAdd, setDocAdd] = useState(false);
//   const [dialogIsOpen, setIsOpen] = useState(false);
//   const preventDefault = (e) => {
//     e.preventDefault();
//   };
//   const uploadDocHandler = (e) => {
//     e.preventDefault();
//     setDocAdd(true);
//   };
//   const hideBlock = (e) => {
//     setDocAdd(false);
//     e.preventDefault();
//   };

//   const openDialog = () => {
//     setIsOpen(true);
//   };

//   const onDialogClose = () => {
//     setIsOpen(false);
//   };

//   return (
//     <div className="pl-6 pr-6">
//       <h5 className="text-blue-800 font-semibold text-2xl mb-2">
//         Payment options
//       </h5>
//       <p class="text-gray-700 mb-3">
//         This annual payment covers the cost of inviting additional Workers to
//         connect with Clients.
//       </p>
//       <div>
//         <div className="w-full">
//           <form>
//             <Card className=" p-7 border border-gray-200 rounded-xl">
//               <h4 className="font-bold text-gray-700 text-base mb-1">Seats</h4>
//               <p className="text-gray-700 mb-7">3 additional Worker(s)</p>
//               <h4 className="font-bold text-gray-700 text-base mb-1">
//                 Annual Fee
//               </h4>
//               <p className="text-gray-700">$308.00</p>
//             </Card>
//             <Card className=" p-7 border border-gray-200 rounded-xl mt-7">
//               {/* <h5 className="text-blue-800 font-semibold text-2xl mb-7">
//                 Payment options
//               </h5> */}
//               <h4 className="font-bold text-gray-700 text-base mb-5">
//                 Select a payment method
//               </h4>
//               <div className="inline-flex rounded-md  mb-7 validation-filter">
//                 <Segment className="payment-btn" onClick={preventDefault}>
//                   <Segment.Item value="Stripe">
//                     <span onClick={uploadDocHandler}>
//                       <img src="/assets/icons-svg/stripe.svg" alt="" />
//                       Stripe
//                     </span>
//                   </Segment.Item>
//                   <Segment.Item value="Paypal">
//                     <span onClick={hideBlock}>
//                       <img src="/assets/icons-svg/paypal.svg" alt="" /> Paypal
//                     </span>
//                   </Segment.Item>
//                 </Segment>
//               </div>
//               {docAdd && (
//                 <React.Fragment>
//                   <h4 className="font-bold text-gray-700 text-base mb-5">
//                     Card information
//                   </h4>
//                   <div className="flex gap-4">
//                     <div className="w-4/5 mb-4">
//                       <label
//                         class="block text-gray-700  mb-2 text-xs font-medium"
//                         for="Trading Name"
//                       >
//                         Name on card
//                       </label>
//                       <span className="input-wrapper">
//                         <input
//                           class="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                           type="text"
//                           name="ccNumber"
//                           autocomplete="off"
//                           placeholder="Name on card"
//                           value=""
//                         />
//                       </span>
//                     </div>
//                     <div className="form-item vertical w-1/5 mb-4">
//                       <label className="form-label  block text-gray-700  mb-2 text-xs font-medium">
//                         Expiry
//                       </label>
//                       <span className="input-wrapper">
//                         <input
//                           className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                           type="text"
//                           name="cardExpiry"
//                           autocomplete="off"
//                           placeholder="XX / XX"
//                           value="XX / XX"
//                           inputmode="numeric"
//                         />
//                       </span>
//                     </div>
//                   </div>
//                   <div className="flex gap-4">
//                     <div className="w-4/5 mb-4">
//                       <label
//                         class="block text-gray-700  mb-2 text-xs font-medium"
//                         for="Trading Name"
//                       >
//                         Card number
//                       </label>
//                       <span className="input-wrapper">
//                         <input
//                           class="pl-12 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                           type="text"
//                           name="ccNumber"
//                           autocomplete="off"
//                           placeholder="•••• •••• •••• ••••"
//                           value=""
//                           inputmode="numeric"
//                         />
//                         <div class="input-suffix-end">
//                           <svg
//                             width="18"
//                             height="19"
//                             viewBox="0 0 18 19"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M16.5 8.33h-15m0-1.35v5.7c0 .84 0 1.26.163 1.58a1.5 1.5 0 0 0 .656.656c.32.163.74.163 1.581.163h10.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656c.163-.32.163-.74.163-1.58v-5.7c0-.841 0-1.261-.163-1.582a1.5 1.5 0 0 0-.656-.655c-.32-.164-.74-.164-1.581-.164H3.9c-.84 0-1.26 0-1.581.164a1.5 1.5 0 0 0-.656.655C1.5 5.72 1.5 6.14 1.5 6.98z"
//                               stroke="#000"
//                               stroke-width="1.5"
//                               stroke-linecap="round"
//                               stroke-linejoin="round"
//                             />
//                           </svg>
//                         </div>
//                       </span>
//                     </div>
//                     <div className="form-item vertical w-1/5 mb-4">
//                       <label className="form-label  block text-gray-700  mb-2 text-xs font-medium">
//                         CVV
//                       </label>
//                       <span className="input-wrapper">
//                         <input
//                           className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                           type="text"
//                           name="cardExpiry"
//                           autocomplete="off"
//                           placeholder="•••"
//                           value="•••"
//                           inputmode="numeric"
//                         />
//                       </span>
//                     </div>
//                   </div>
//                   <Radio className="mt-3">
//                     I understand and accept that this licensing fee is valid
//                     only for one year and is non-refundable during that period.
//                   </Radio>
//                 </React.Fragment>
//               )}
//             </Card>
//           </form>
//         </div>
//         <Dialog
//           isOpen={dialogIsOpen}
//           onClose={onDialogClose}
//           onRequestClose={onDialogClose}
//           bodyOpenClassName="overflow-hidden"
//         > 
//           <div className="flex border-b mb-5 pb-5 px-6 pt-6">
//             <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
//               <img src="assets/icons-svg/building.svg" alt="" />
//             </span>
//             <div>
//               <h5 className="mb-1 text-gray-900 text-base font-semibold">
//                 Connect with [Client name]
//               </h5>
//               <p className="text-gray-600 ">
//                 Ensure a successful connection with the client by completing
//                 these steps
//               </p>
//             </div>
//           </div>
//         </Dialog>
//       </div>
//     </div>
//   );
// };
// export default PaymentMethod;

import React, { useEffect, useState } from "react";
import Card from "../../common/element/Card";
import { Link } from "react-router-dom";
import BtnPrimary from "../../common/element/Buttons/btnPrimary";
import Segment from "../../common/element/Segment";
import Radio from "../../common/element/Radio";
import Dialog from "../../common/element/Dialog";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { stripePaymentForWorkerSeats } from "../../actions/provider-portal/worker/stripePaymentForWorkerSeats";
import { useDispatch } from "react-redux";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GetWokerSeatPayment } from "../../actions/provider-portal/worker/GetWokerSeatPayment";
import { PaypalCheckoutForWorkerSeats } from "../../actions/provider-portal/worker/PaypalCheckoutForWorkerSeats";
const PaymentMethod = ({ newSeat }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [docAdd, setDocAdd] = useState(false);
  const [paypalMethod, setPaypalMethod] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [workerFees, setWorkerFees] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const preventDefault = (e) => e.preventDefault();
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const handlePaymentSuccess = (details) => {
    setPaymentSuccess(true);
  };

  const handleSubmit = async (checkpayment) => {
    // event.preventDefault();
    let payload = {
      organisation_id: loginUserData?.organisation_id,
      newSeatsCount: newSeat,
      // provider_type_id: "",
    };
    if (checkpayment == "stripe") {
      dispatch(stripePaymentForWorkerSeats(payload)).then((response) => {
        if (response.success) {
          // window.open(response?.data?.data?.sessionUrl, "_blank");
          window.location.href = response?.data?.data?.sessionUrl;
        }
      });
    } else {
      dispatch(PaypalCheckoutForWorkerSeats(payload)).then((response) => {
        if (response.success) {
          window.location.href = response?.data?.data?.approvalLink;

          // window.open(response?.data?.data?.approvalLink, "_blank");
        }
      });
    }
  };

  const currencyCode = "USD";
  const itemPrice = "99.00";

  const PaymentForm = ({ onPaymentSuccess }) => {
    const [showPaymentButton, setShowPaymentButton] = useState(false);

    const createOrder = (data, actions) => {
      return actions.order.create({
        purchase_units: [
          {
            description: "Books",
            amount: {
              currency_code: currencyCode,
              value: itemPrice,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      });
    };

    const onApprove = (data, actions) => {
      return actions.order
        .capture()
        .then(function (details) {
          onPaymentSuccess(details);
        })
        .catch(function (error) {
          console.error("Payment capture error:", error);
        });
    };

    return (
      <>
        {/* <h2>Books Sample</h2>
      <span>{currencyCode}{itemPrice}</span> */}
        {/* <button onClick={() => setShowPaymentButton(true)} type='submit'>Buy Now</button> */}

        {/* {showPaymentButton && ( */}
        <PayPalButtons
          fundingSource="paypal" // Only show PayPal button, hiding debit/credit option
          // style={{
          //   layout: "vertical",
          //   color: "white", // Set to white or another close match to your background
          // }}
          style={{
            layout: "vertical",
            color: "white", // Set to a minimal color, to be covered by custom overlay
            height: 35,
          }}
          createOrder={createOrder}
          onApprove={onApprove}
        />
        {/* )} */}
      </>
    );
  };

  useEffect(() => {
    let payload = {
      provider_org_id: loginUserData?.organisation_id,
      newSeatCount: newSeat,
    };
    dispatch(GetWokerSeatPayment(payload)).then((response) => {
      if (response.success) {
        console.log(response.data);
        setWorkerFees(response.data);
        // workerFees,
      }
    });
  }, []);

  return (
    <div className="pl-6 pr-6">
      <h5 className="text-blue-800 font-semibold text-2xl mb-2">
        Payment options
      </h5>
      <p className="text-gray-700 mb-3">
        This annual payment covers the cost of inviting additional Workers to
        connect with Clients.
      </p>
      <div>
        <div className="w-full">
          <form>
            <Card className="p-7 border border-gray-200 rounded-xl">
              <h4 className="font-bold text-gray-700 text-base mb-1">Seats</h4>
              <p className="text-gray-700 mb-7">
                {newSeat} additional Worker(s)
              </p>
              <h4 className="font-bold text-gray-700 text-base mb-1">
                Annual Fee
              </h4>
              <p className="text-gray-700">$ {workerFees}</p>
            </Card>
            <Card className="p-7 border border-gray-200 rounded-xl mt-7">
              <h4 className="font-bold text-gray-700 text-base mb-5">
                Select a payment method
              </h4>
              <div className="inline-flex rounded-md mb-7 validation-filter">
                <Segment className="payment-btn" onClick={preventDefault}>
                  <Segment.Item value="Stripe">
                    <span
                      onClick={() => {
                        handleSubmit("stripe");
                        // setDocAdd(true);
                        setPaypalMethod(false);
                      }}
                    >
                      <img src="/assets/icons-svg/stripe.svg" alt="" /> Stripe
                    </span>
                  </Segment.Item>
                  <Segment.Item value="Paypal">
                    <span
                      onClick={() => {
                        handleSubmit();
                        // setDocAdd(true);
                        // setPaypalMethod(false);
                      }}
                    >
                      <img src="/assets/icons-svg/paypal.svg" alt="" /> Paypal
                    </span>
                  </Segment.Item>
                </Segment>
              </div>
            </Card>
          </form>
        </div>
        <Dialog isOpen={dialogIsOpen} onClose={() => setIsOpen(false)}>
          <div className="flex border-b mb-5 pb-5 px-6 pt-6">
            <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
              <img src="assets/icons-svg/building.svg" alt="" />
            </span>
            <div>
              <h5 className="mb-1 text-gray-900 text-base font-semibold">
                Connect with [Client name]
              </h5>
              <p className="text-gray-600">
                Ensure a successful connection with the client by completing
                these steps
              </p>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default PaymentMethod;
