import React,{useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollBar from "../../../../../../../common/element/ScrollBar/ScrollBar";
import Tabs from "../../../../../../../common/element/Tabs";
import Checkbox from "../../../../../../../common/element/Checkbox";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimaryLight from "../../../../../../../common/element/Buttons/primaryLightBtn";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import RichTextEditor from "../../../../../../../common/snippet/RichTextEditor";
import ReactPlayer from 'react-player'
import {Document} from "react-pdf";
import {Page} from "react-pdf";
import { Link } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { TailSpin } from "react-loader-spinner";
import {ExportInductionModulePdfRange} from "../../../../../../../actions/Admin-Client/AllCompanyInduction";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";

let checkindexNumber=0;
const InductionView = ({
  closeViewInductionModule,
  handleEditinductionModule,
  buttonDisable,
  editBtnHide
})=>{
   const defaultLayoutPluginInstance = defaultLayoutPlugin({
      sidebarTabs: (defaultTabs) => [],
    });
    const dispatch = useDispatch();
    const handleUnauthorizedAccess = useUnauthorizedHandler()
    const { userName } = JSON.parse(localStorage.getItem("LoginUserData"));
    const GettAllFunctions = useSelector((state) => state);
    const { TabNav, TabList, TabContent } = Tabs;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [startPage, setstartPage] = useState(2);
    const [endPage, setendPage] = useState(3);
    const [checkData, setcheckData] = useState("");
    const [UrlPdf, setUrlPdf] = useState("");
    const [defaultTab, SetdefaultTab] = useState("tab1");
    const [pdfFile, SetPdfFile] = useState("");
    const [spiner, Setspiner] = useState(false);
    const [moduleData, SetmoduleData] = useState(
      GettAllFunctions?.InductionModuleData
    );
    const [dragging, setDragging] = useState(false);
    const [checkIsLastmodule, setcheckIsLastmodule] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState(null);
    const [indexData, SetindexData] = useState(0);
    // function onDocumentLoadSuccess({ numPages }) {
    //   setNumPages(numPages);
    // }

    useEffect(() => {
      SetmoduleData(GettAllFunctions.InductionModuleData);
      checkindexNumber=0
    }, [GettAllFunctions]);

    const ViewModule = (e) => {
      if(e.index==0){
        checkindexNumber=1
      }
      if (indexData!=e.index || checkindexNumber==1 ) {
        if(e.index!==0){
          checkindexNumber=0
        }
        SetindexData(e.index);
      SetPdfFile("")
      Setspiner(true)
      const parsedObject = moduleData?.[e.index]?.pdf_page_range;
      if(
        parsedObject && Object.keys(parsedObject).length !== 0 && 
        parsedObject.constructor === Object
        ){
        const fileExtension = moduleData?.[e.index]?.content_info
        ?.split(".")
        ?.pop()
        ?.toLowerCase();
        let stringifyData=moduleData?.[e.index]?.pdf_page_range
        let PdfData={
          content_info: moduleData?.[e.index]?.content_info,
          startPage: stringifyData?.startPage, 
          endPage:stringifyData?.endPage
        }
        if(
          moduleData?.[e.index]?.content_info && 
          fileExtension=="pdf" && 
          stringifyData?.startPage && 
          stringifyData?.endPage
          ){
        dispatch(ExportInductionModulePdfRange(PdfData))
        .then((response) => {
          if (response.success) {
            Setspiner(false)
            SetPdfFile(response.data.file)
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          } else {
            Setspiner(false)
          }
        })
        .catch((error) => {
        });
      }
      else{
        SetPdfFile(moduleData?.[e.index]?.content_info)
        Setspiner(false)
      }
    }
    else{
      SetPdfFile(moduleData?.[e.index]?.content_info)
      Setspiner(false)
    }

    if (moduleData.length - 1 == e.index) {
      setcheckIsLastmodule(true);
    } else {
      setcheckIsLastmodule(false);
    }
      SetdefaultTab(e.module_name);
      if (moduleData[e?.index]?.content_info_type == "file") {
        let fileName;
        if (moduleData[e?.index]?.company_ind_file?.name !== undefined) {
          fileName = moduleData[e?.index]?.company_ind_file.name;
        } else {
          fileName = moduleData[e?.index]?.content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName?.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {  
            setcheckData("pdf");
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          let render = new FileReader();
          render.onload = (event) => {
            // Use '=' instead of '()' here
            setUrlPdf(event?.target?.result);
          };
          if (moduleData[e?.index]?.company_ind_file?.name !== undefined) {
            render?.readAsDataURL(moduleData[e?.index]?.company_ind_file);
          } else {
            setUrlPdf(moduleData[e?.index]?.content_info);
          }
  
          setcheckData("image");
        }
           }
      if (moduleData[e?.index]?.content_info_type == "text") {
        setcheckData("text");
      }
      if (
        moduleData[e?.index]?.content_info_type == "video_url" ||
        moduleData[e?.index]?.content_info_type == "file_video"
        ) {
        setcheckData("video_url");
      }
      if (moduleData[e?.index]?.content_info_type == "url") {
        setcheckData("url");
      }
      if(
        moduleData[e?.index]?.content_info_type==null || 
        moduleData[e?.index]?.content_info_type==""
        ){
        setcheckData("")
      }
    };
  }
   
  const handleMouseDown = (e) => {
      setDragging(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    };
  
    const handleMouseUp = () => {
      setDragging(false);
      setStartPosition(null);
    };
    const handleMouseMove = (e) => {
      if (dragging && startPosition) {
        const deltaX = e.clientX - startPosition.x;
        const deltaY = e.clientY - startPosition.y;
        setPosition({
          x: position.x + deltaX,
          y: position.y + deltaY,
        });
        setStartPosition({ x: e.clientX, y: e.clientY });
      }
    };
  
    const handleDownload = () => {
      const link = document.createElement("a");
      link.href = UrlPdf;
      link.download = "image.jpg";
      link.click();
    };
    const handlePrint = () => {
      const printWindow = window.open("", "", "width=600,height=600");
      printWindow.document.open();
      printWindow.document.write(`<img src="${UrlPdf}" alt="Image" />`);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    };
    const [zoom, setZoom] = useState(100);
  
    const handleZoomOut = () => {
      setZoom(zoom + 10);
    };
  
    const handleZoomIn = () => {
      if (zoom > 1) {
        setZoom(zoom - 10);
      }
    };
    const closePenne = () => {
      closeViewInductionModule();
    };
    const inductionCptOpen = () => {
      let index = defaultTab == "tab1" ? 0 : indexData + 1;
      if (moduleData.length > index) {
        ViewModule({
          index: index,
          module_name: moduleData[index].module_name + index,
        });
      }
      if (moduleData.length - 1 === index) {
        setcheckIsLastmodule(true);
      }
    };
    // const {InductionModuleData} = useSelector((state)=>state)
    const closeViewInduction = () => {
      closeViewInductionModule();
    };
    const handleEditinduction = () => {
      handleEditinductionModule();
    };
return(
<React.Fragment>
   <Tabs value={defaultTab} variant="pill">
      <div className="flex flex-auto w-full">
         <div className="flex flex-col  bg-blue-50 border-r border-gray-300 w-1/4">
            <div className ="overflow-y-auto h-595">
               <ScrollBar>
                  <div className=" py-6 px-3">
                     <TabList className="flex-col left-side-block">
                     <TabNav
                      onClick={() =>
                        ViewModule({
                          index: 0,
                          module_name: "tab1",
                        })
                      }
                      value="tab1"
                    >
                      Induction Details
                    </TabNav>
                        <hr className="border-t border-gray-300 mt-4 mb-3 w-full"/>
                        {moduleData?.map((items, index) => {
                      return (
                        <TabNav
                          value={items.module_name + index}
                          onClick={() =>
                            ViewModule({
                              index: index,
                              module_name: items.module_name + index,
                            })
                          }
                          key={index}
                        >
                          {index + 1}.{items.module_name}
                          <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-gray-600">
                            <svg
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                stroke="#475467"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </TabNav>
                      );
                    })}
                     </TabList>
                  </div>
               </ScrollBar>
            </div>
         </div>
         <div className="w-3/4">
         <TabContent value="tab1" className="pr-1">
              <div className="overflow-y-auto h-595">
                <ScrollBar>
                  <div className="mb-6 px-5 pt-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      {GettAllFunctions.inductionDetailData.company_ind_name}{" "}
                      for {userName.first_name + " " + userName.last_name}
                    </h4>
                    <p className="text-back font-medium">
                      {GettAllFunctions.inductionDetailData.company_ind_desc}
                    </p>
                  </div>
                  <div className="mb-6 px-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      There are {moduleData?.length} modules in this Induction
                    </h4>
                    <p className="text-back font-medium">
                      To get started, simply click on a module you wish to
                      complete. Each module includes important information and
                      instructions to ensure a safe working environment. As you
                      complete each module, your progress will be tracked and
                      saved. If you need to take a break or log out, don't worry
                      - you can pick up where you left off at any time. Thank
                      you for participating in this induction.
                    </p>
                  </div>
                  <div className="mb-6 px-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      The Modules progress are tracked in different states:
                    </h4>
                    <ul className="flex flex-col gap-2 mb-0">
                      <li className="flex gap-1">
                        <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-gray-600">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span>=</span> The module is awaiting action.
                      </li>
                      <li className="flex gap-1">
                        <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-blue-700">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1592_20677)">
                              <path
                                d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                stroke="#5786CC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1592_20677">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0 0.691406)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>=</span> You have started your module, but there
                        is more to do.
                      </li>
                      <li className="flex gap-1">
                        <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-success-600">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m10 3.691-5.5 5.5-2.5-2.5"
                              stroke="#039855"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span>=</span>You have successfully finished the module.
                        Well done!
                      </li>
                    </ul>
                  </div>
                </ScrollBar>
              </div>
            </TabContent>
            <TabContent
              value={defaultTab == "tab1" ? "tab2" : defaultTab}
              className="pr-1"
            >
              <div className="overflow-y-auto h-595">
                <ScrollBar>
                  <div className="border-b border-gray-300 pb-3 px-5 pt-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      {moduleData?.[indexData]?.module_name}
                    </h4>
                    <p className="text-back font-medium">
                      {moduleData?.[indexData]?.module_desc}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="w-7/12 p-4 rich_text_view">
                      {checkData == "text" && (
                        <RichTextEditor
                        readOnly={true}
                        value={moduleData?.[indexData]?.content_info}
                        toolbarHidden={true}
                      />
                        // <React.Fragment>
                        //   {moduleData?.[indexData]?.content_info.replace(
                        //     /<[^>]*>/g,
                        //     ""
                        //   )}
                        // </React.Fragment>
                      )}
                      {checkData == "url" && (
                        <React.Fragment>
                          <div className="Image h-80 rounded-t-lg bg-gray-200">
                            <img src="/assets/png-icons/pic.png" alt="" />
                          </div>
                          <div className="link-set bg-gray-100 p-5 rounded-b-lg">
                            <div className="flex ">
                              <Link
                                to={`${moduleData?.[indexData]?.content_info}`}
                                // href={moduleData?.[indexData]?.content_info}
                                className="flex font-semibold text-black gap-2"
                                target="_blank"
                              >
                                {moduleData?.[indexData]?.content_link_title}
                                <span>
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 8.125v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093C2.5 6.69 2.5 7.392 2.5 8.792v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273h5.333c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.093-1.092c.272-.535.272-1.235.272-2.635v-1.833"
                                      stroke="#667085"
                                      strokeWidth="1.667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </Link>
                            </div>
                            <p className="mt-2 text-black break-words">
                              {moduleData?.[indexData]?.content_info}
                            </p>
                          </div>
                        </React.Fragment>
                      )}
                      {checkData == "image" && (
                        <>
                          <div className="iFrame-Header ">
                            <div className="text-white text-xs">
                              {moduleData?.[indexData]?.company_ind_file?.name}
                            </div>
                            <div className="btn-group">
                              <button
                                onClick={() => handleZoomIn()}
                                className="bg-white"
                              >
                                -
                              </button>
                              <button
                                onClick={() => handleZoomOut()}
                                className="bg-white"
                              >
                                +
                              </button>
                            </div>
                            <div className="flex gap-3">
                              <button onClick={() => handleDownload()}>
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                                    stroke="#fff"
                                    strokeWidth="1.667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                              <button onClick={() => handlePrint()}>
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                                    stroke="#fff"
                                    strokeWidth="1.667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div
                            className="image-container"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                          >
                            <img
                              style={{
                                scale: `${zoom}% `,
                                transform: `translate(${position.x}px, ${position.y}px)`,
                              }}
                              src={UrlPdf}
                              alt="Image"
                            />
                          </div>
                        </>
                      )}
                      {checkData == "video_url" && (
                        <ReactPlayer
                          url={moduleData?.[indexData]?.content_info}
                          // url="https://www.youtube.com/watch?v=MfImpt4zx-w&pp=ygUbcGluZGFuIGRlIGpheWUgc2FqamFuIGFkZWVi"
                          className="video-player"
                          playing={true}
                          controls={true}
                        />
                      )}
                      {spiner && (
                      <div>
                        <div className="blur">
                          <div className="flex">
                            <label className="font-medium text-gray-700 w-1/5">
                              Site Address*
                            </label>
                            <div className="w-4/5 pl-2">
                              <input
                                type="text"
                                placeholder="Street Address*"
                                className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                              />
                              <input
                                type="text"
                                placeholder="Street Address*"
                                className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center mb-5 mt-5 pt-5">
                          <TailSpin color="blue" radius={"8px"} />
                        </div>
                        <div className="blur">
                          <div className="flex mb-5 border-t mt-5 pt-5">
                            <label className="font-medium text-gray-700 w-1/5">
                              Evacuation Diagram
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                      {/* {checkData == "pdf" && UrlPdf && ( */}
                      {checkData == "pdf" && pdfFile && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={pdfFile}
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </Worker>
                      )}
                    </div>
                    <div className="w-5/12 border-l border-gray-300">
                  {moduleData?.[indexData]?.module_ques_data?.[0].question 
                  &&  
                  moduleData?.[indexData]?.module_ques_data?.map(
                        (items, index) => {
                          return (
                            <React.Fragment key={index}>
                              <h4 className="text-black font-semibold mb-3 pl-4 pr-5 pt-5">
                                Q{index + 1}. {items.question}
                              </h4>
                              <div
                                className={`flex flex-col gap-2 pr-5 pl-4 pb-4 ${
                                  moduleData?.[indexData]?.module_ques_data
                                    .length -
                                    1 !==
                                  index
                                    ? "border-b border-gray-300"
                                    : ""
                                }`}
                              >
                                {items?.ques_ans_data?.map(
                                  (checkbox, number) => {
                                    return (
                                      <div
                                      className={`${
                                        checkbox.is_correct_answer
                                          ? "success-checkbox"
                                          : "error-checkbox"
                                      }`}
                                      key={number}
                                        >
                                          <Checkbox checked={checkbox.is_correct_answer}>{checkbox.answer}</Checkbox>
                                        </div>
                                      // <React.Fragment key={number}>
                                      //   <Checkbox 
                                      //   className={checkbox.is_correct_answer ? 'success-checkbox' : 'error-checkbox' }
                                      //       >
                                      //         {checkbox.answer}
                                      //         </Checkbox>
                                      // </React.Fragment>
                                    );
                                  }
                                )}
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  </div>
                </ScrollBar>
              </div>
            </TabContent>
           
         </div>
      </div>
   </Tabs>
   <div className="flex dailog-footer mt-0 justify-end">
        <BtnLight
          onClick={() => closeViewInduction()}
          className="mr-1.5 w-auto px-4"
          variant="plain"
        >
          Cancel
        </BtnLight>
        {editBtnHide==undefined && (
          <BtnPrimaryLight
            className="ml-1.5 w-auto px-4 flex gap-2 items-center"
            onClick={() => handleEditinduction()}
            disabled={buttonDisable?buttonDisable:false}
          >
            Edit induction
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.667 8.333 12.333 5M2.75 17.917l2.82-.314c.345-.038.517-.057.678-.11.143-.046.279-.11.404-.194.142-.093.264-.215.51-.46L18.166 5.832A2.357 2.357 0 1 0 14.833 2.5L3.828 13.505c-.245.245-.368.368-.46.51a1.667 1.667 0 0 0-.195.403c-.052.161-.071.334-.11.678l-.313 2.82z"
                stroke="#5786cc"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnPrimaryLight>
        )}
        {checkIsLastmodule ? (
          <BtnPrimary
            onClick={() => closePenne()}
            className="ml-1.5 w-auto px-4 flex gap-2 items-center"
          >
            Close
          </BtnPrimary>
        ) : (
          <BtnPrimary
            onClick={() => inductionCptOpen()}
            className="ml-1.5 w-auto px-4 flex gap-2 items-center"
          >
            View {defaultTab === "tab1" ? "first" : "next"} module
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.167 10.487h11.666m0 0L10 4.654m5.833 5.833L10 16.321"
                stroke="#fff"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnPrimary>
        )}
      </div>
 
</React.Fragment>
)
}
export default InductionView