import React,{useCallback,useEffect,useState} from 'react'
import { setBreadcrumbs } from '../../../../../../redux/classes/breadcrumbsSlice'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUnauthorizedHandler from '../../../../../../common/snippet/useUnauthorizedHandler';
const Invoices = (props) => {
    const dispatch = useDispatch();
    const { id, siteId } = useParams();
    const handleUnauthorizedAccess = useUnauthorizedHandler()
    const { ClientSpecificProfileData } = useSelector((state) => state)
    
    useEffect(() => {
        dispatch(
          setBreadcrumbs([
            { label: "View All Clients", url: "/admin/client/view-all-clients", home: "/admin/client/dashboard" },
    
            {
              label: ClientSpecificProfileData?.trading_name ? ClientSpecificProfileData?.trading_name : "",
              url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
            },
            {
              label: "Invoices",
              url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
            },
            
          ])
        );
      }, []);
  return (
    <div>
      
    </div>
  )
}

export default Invoices
