import React, { useState, useCallback, useEffect } from "react";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
// import DocumentsList from './DocumentList';
import moment from "moment";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import Checkbox from "../../../../../../common/element/Checkbox";
import fileCheck from "../../../../../../assets/icons-svg/file-check.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../../../../../common/element/Table";
import {
  GetAllAssignedChecklistOfProvider,
  UpdateProviderChecklistAssign,
} from "../../../../../../actions/admin-provider/clients/CheckList";
const CheckList = ({ indivisualData }) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [providerCompCheckList, setProviderCompCheckList] = useState([]);
  const [checklistIds, setChecklistIds] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [removeChecklistIds, setRemoveChecklistIds] = useState([]);

  const OnGetAllAssignedChecklistOfProvider = useCallback(async () => {
    if (true) {
      try {
        const payload = {
          provider_org_id: decryptId(id),
          client_org_id: indivisualData?.organisation_id,
        };
        const response = await dispatch(
          GetAllAssignedChecklistOfProvider(payload)
        );
        const { success, data, status } = response;
        if (success) {
          const arrayData = data?.data;
          // setAlreadyAssigned(rows); // Assuming `rows` is what you need to set
          setProviderCompCheckList(arrayData);
          const alreadyAssignedChecklist = arrayData.filter(
            (ele) =>
              ele?.compAssigns && Object.keys(ele?.compAssigns)?.length > 0
          );
          setCheckedItems(alreadyAssignedChecklist);
        } else if (status === 401) {
          // handleUnauthorizedAccess(); // Uncomment and define this if needed
        } else {
          console.error("Failed to fetch compliance checklist:", response);
          // Handle other types of errors or unsuccessful responses
        }
      } catch (error) {
        console.error("Failed to fetch compliance checklist:", error);
        // Handle unexpected errors
      }
    }
  }, [dispatch, id, indivisualData?.organisation_id]);

  useEffect(() => {
    OnGetAllAssignedChecklistOfProvider();
  }, [id, indivisualData?.organisation_id]);

  const onCheckboxChange = (isChecked, item) => {
    const existingIndex = checkedItems.findIndex(
      (ele) => ele.checklist_id === item.checklist_id
    );

    if (isChecked && existingIndex === -1) {
      // Item is checked (added)
      setCheckedItems((prevItems) => [...prevItems, item]);
      setChecklistIds((prevIds) => [...prevIds, item.checklist_id]);
      setRemoveChecklistIds((prevIds) =>
        prevIds.filter((id) => id !== item.checklist_id)
      );
    } else if (!isChecked && existingIndex !== -1) {
      // Item is unchecked (removed)
      setCheckedItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(existingIndex, 1);
        return updatedItems;
      });
      setRemoveChecklistIds((prevIds) => [...prevIds, item.checklist_id]);
      setChecklistIds((prevIds) =>
        prevIds.filter((id) => id !== item.checklist_id)
      );
    }
  };
  const onCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const onConfirm = () => {
    const payload = {
      assignChecklistIds: JSON.stringify(checklistIds),
      removeChecklistIds: JSON.stringify(removeChecklistIds),
      provider_org_id: decryptId(id),
      client_org_id: indivisualData?.organisation_id,
    };
    dispatch(UpdateProviderChecklistAssign(payload))
      .then((res) => {
        if (res?.success === true) {
          // OnGetProviderDocsAgainstClient()
          setOpenConfirmDialog(false);
          setRemoveChecklistIds([]);
          setCheckedItems([]);
          setChecklistIds([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onCloseAssignChecklist = () => {
    setCheckedItems([]);
  };
  console.log("indivisualData::", indivisualData?.organisation_id);
  return (
    <React.Fragment>
      {/* <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
      </div> */}
      <div className="px-6">
        {/* <div className="flex justify-between mb-5 mt-5">
          <h5 className="text-xl text-gray-900 font-semibold">
            Provider Checklist(s)
          </h5>
        </div> */}
        <div className="border rounded-lg border-gray-200">
          <div className="h-64 overflow-auto">
            <Scrollbar>
              <Table>
                <THead>
                  <Tr>
                    <Th className="flex justify-between gap-1">
                      <span className="flex items-center gap-1">
                        Checklist
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                            stroke="#475467"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </Th>
                    <Th>
                      <span className="flex items-center gap-1 justify-end">
                        Expiry date
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                            stroke="#475467"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </Th>
                  </Tr>
                </THead>
                <TBody>
                  {providerCompCheckList?.length != 0 &&
                    providerCompCheckList?.map((item) => (
                      <Tr key={item.checklist_id}>
                        <Td className="flex gap-2">
                          <Checkbox
                            className="cursor-pointer"
                            checked={checkedItems.some(
                              (checkedItem) =>
                                checkedItem.checklist_id === item.checklist_id
                            )}
                            onChange={(e) => onCheckboxChange(e, item)}
                          />
                          {item?.checklist_name ? item?.checklist_name : "-"}
                        </Td>
                        <Td className="text-right">
                          {item.checklist_renewal_date
                            ? moment(item?.checklist_renewal_date).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Td>
                      </Tr>
                    ))}
                </TBody>
              </Table>
              {providerCompCheckList?.length == 0 && (
                <div className="h-full w-full text-center mt-2">
                  No Data Available!
                </div>
              )}
            </Scrollbar>
          </div>
        </div>
      </div>
      <div className="flex dailog-footer ">
        <BtnLight
          className="mr-1.5"
          variant="plain"
          onClick={onCloseAssignChecklist}
        >
          Cancel
        </BtnLight>
        <BtnPrimary
          type="submit"
          onClick={() => {
            setOpenConfirmDialog(true);
          }}
          className="ml-1.5"
          // disabled={isSaving}
        >
          Save updates
          {/* {isSaving ? "Saving..." : "Save updates"} */}
        </BtnPrimary>
      </div>
      <Dialog
        isOpen={openConfirmDialog}
        onClose={onCloseConfirmDialog}
        onRequestClose={onCloseConfirmDialog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.01331"
                y="0.5"
                width="47"
                height="47"
                rx="23.5"
                fill="#D2EBFF"
              />
              <rect
                x="1.01331"
                y="0.5"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M26.5133 14.2695V18.4001C26.5133 18.9601 26.5133 19.2401 26.6223 19.4541C26.7182 19.6422 26.8712 19.7952 27.0593 19.8911C27.2732 20.0001 27.5533 20.0001 28.1133 20.0001H32.2438M22.3633 24.0022C22.5395 23.5014 22.8873 23.079 23.345 22.81C23.8028 22.5409 24.341 22.4426 24.8643 22.5324C25.3876 22.6221 25.8623 22.8942 26.2042 23.3004C26.5462 23.7066 26.7333 24.2207 26.7325 24.7517C26.7325 26.2506 24.4842 27 24.4842 27M24.5133 30H24.5233M26.5133 14H21.3133C19.6331 14 18.7931 14 18.1513 14.327C17.5868 14.6146 17.1279 15.0735 16.8403 15.638C16.5133 16.2798 16.5133 17.1198 16.5133 18.8V29.2C16.5133 30.8802 16.5133 31.7202 16.8403 32.362C17.1279 32.9265 17.5868 33.3854 18.1513 33.673C18.7931 34 19.6331 34 21.3133 34H27.7133C29.3935 34 30.2335 34 30.8753 33.673C31.4398 33.3854 31.8987 32.9265 32.1863 32.362C32.5133 31.7202 32.5133 30.8802 32.5133 29.2V20L26.5133 14Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to make these updates?
          </p>
          <p className="text-gray-600 text-center">
            If additional checklists are assigned, Providers and Workers will
            need their documents reviewed again.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onCloseConfirmDialog}>Close</BtnLight>
          <BtnPrimary onClick={() => onConfirm()}>Confirm</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default CheckList;
