import { useState, useEffect, useRef, useCallback } from "react";
import Card from "../../../common/element/Card";
import { connect, useDispatch } from "react-redux";
import Heading from "../../../common/element/Heading";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import Radio from "../../../common/element/Radio";
import ThreeDotLoader from "../../../common/element/ButtonLoader/ThreeDotLoader";
import { notify } from "react-notify-toast";
import * as userActions from "../../../actions/index";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import logoImage from "../../../assets/logo.svg";
import { setAllFunction } from "../../../actions/index";
import { AllContactTypes } from "../../../redux/classes/createSlice";
import { setAllRole } from "../../../actions/index";
import Loader from "../../../common/loader";
import { useSelector } from "react-redux";
import { UpdateCheckExistUser } from "../../../redux/classes/provider-portal/checkExistUser";
let GetAllFunctionData;
const TwoFA = (props) => {
  const { checkExistUser } = useSelector((state) => state);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const [trustDevide, setTrustDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [threeDotLoader, setThreeDotLoader] = useState(false);
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("login"))
      ? JSON.parse(localStorage.getItem("login"))
      : ""
  );

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const otp = urlSearchParams.get("otp");
    setEmail(email);
    if (otp) {
      setDigit1(otp.charAt(0));
      setDigit2(otp.charAt(1));
      setDigit3(otp.charAt(2));
      setDigit4(otp.charAt(3));
    }
  }, []);
  //   GetAllfunctions Api
  const dispatch = useDispatch();

  const GetAllFunctions = useCallback(async () => {
    dispatch(userActions.GetAllFunctions()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        GetAllFunctionData = arrayData;
        dispatch(setAllFunction(arrayData));
        GetAllRoleByFunctionId(GetAllFunctionData);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const GetAllContactTypes = useCallback(async () => {
    dispatch(userActions.GetAllContactTypes()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        dispatch(AllContactTypes(arrayData));
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const GetAllRoleByFunctionId = useCallback(
    async (GetAllFunctionData) => {
      let functionData = GetAllFunctionData.filter(
        (item) => item.function_name === "conserve-team"
      );
      let function_id = functionData[0]?.function_id;
      if (function_id) {
        dispatch(userActions.GetAllRoleByFunctionId(function_id)).then(
          (response) => {
            if (response?.success === true) {
              const arrayData = response?.data;
              dispatch(setAllRole(arrayData));
            } else {
            }
          }
        );
      }
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const handleInputChange = (index, event) => {
    let value = event.target.value.replace(/[^\w\s]/gi, "").toUpperCase();
    value = value.slice(0, 1);
    if (value.length === 1) {
      switch (index) {
        case 0:
          setDigit1(value);
          break;
        case 1:
          setDigit2(value);
          break;
        case 2:
          setDigit3(value);
          break;
        case 3:
          setDigit4(value);
          break;
        default:
          break;
      }

      //  if (event.target.value && index < inputRefs.current.length - 1) {
      //       inputRefs.current[index + 1].focus();
      //     } else if (!event.target.value && index > 0) {
      //       inputRefs.current[index - 1].focus();
      //     }
      // Move focus forward
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0) {
      // Clear the current input value
      switch (index) {
        case 3: //3
          setDigit4(""); //4
          break;
        case 2: //2
          setDigit3(""); //3
          break;
        case 1: //1
          setDigit2(""); //2
          break;
        case 0: // 0
          setDigit1(""); //1
          break;
        default:
          break;
      }

      // Move focus backward
      if (!event.target.value && index > 0) {
        // if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleTrustDeviceChange = () => {
    setTrustDevice(trustDevide === true ? false : true);
  };
  const handleRedendEmail = (e) => {
    const payload = {
      email: email,
    };

    setButtonDisabled(true);
    props
      ?.onResendSignInOtp(payload)
      .then((response) => {
        if (response?.success === true) {
          setButtonDisabled(false);
          notify.show(response?.data, "success");
        } else {
          notify.show(response?.response?.data?.error?.message, "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  const loginSubmitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    const otp = digit1 + digit2 + digit3 + digit4;

    const payload = {
      email: email,
      user_otp: otp,
    };

    // first time loder true
    setThreeDotLoader(true);
    props
      ?.onSignInVerify(payload)
      .then((response) => {
        if (response?.success === true) {
          // if response true then loader stop
          GetAllFunctions();
          GetAllContactTypes();

          setTimeout(() => {
            // Redirects to dashboard page,
            if (response?.data.userData.is_conserve_team) {
              localStorage.setItem(
                "LoginUserData",
                JSON.stringify({
                  userName: response?.data.userData,
                  data: response?.data.data,
                })
              );
              if (response?.data?.data) {
                localStorage.setItem(
                  "permissionData",
                  JSON.stringify(response?.data.data.role)
                );
              }
              if (localStorage.getItem("DocUrl")) {
                navigate(localStorage.getItem("DocUrl"));
                localStorage.getItem("DocUrl", "");
              } else {
                navigate("/admin/users");
              }

              setLoading(false);
            } else {
              if (
                response?.data.userData.user_data.is_worker &&
                response?.data.data == ""
              ) {
                localStorage.setItem(
                  "LoginUserData",
                  JSON.stringify({ userName: response?.data.userData })
                );
                localStorage.setItem(
                  "checkAuth",
                  JSON.stringify({ checkAuth: "worker" })
                );
                if (localStorage.getItem("DocUrl")) {
                  navigate(localStorage.getItem("DocUrl"));
                  localStorage.getItem("DocUrl", "");
                } else {
                  navigate("/worker/dashboard");
                }
                setLoading(false);
              } else {
                localStorage.setItem(
                  "LoginUserData",
                  JSON.stringify({
                    userName: response?.data.userData,
                    functionId:
                      response?.data.data.user_perm_fa.function_id,
                    function_assignment_id:
                      response?.data.data.function_assignment_id,
                    trading_name: response?.data.data.user_perm_fa.org_data
                      ? response?.data.data.user_perm_fa.org_data.trading_name
                      : response?.data.data.user_perm_fa.trading_name,
                    organisation_id:
                      response?.data.data.user_perm_fa.org_data
                        .organisation_id,
                  })
                );
              }
              localStorage.setItem(
                "permission_data",
                JSON.stringify(response?.data.data)
              );
              const loginUserData = JSON.parse(
                localStorage.getItem("LoginUserData")
              );
              if (loginUserData && loginUserData.functionId !== null) {
                let datarolle;
                let GetFilterData = GetAllFunctionData.filter(
                  (item) => loginUserData.functionId == item.function_id
                );
                if (GetFilterData.length > 0) {
                  datarolle = GetFilterData[0].function_name;
                  if (localStorage.getItem("DocUrl")) {
                    navigate(localStorage.getItem("DocUrl"));
                    localStorage.getItem("DocUrl", "");
                  } else {
                    if (datarolle === "client") {
                      navigate("/client/dashboard");
                    } else if (datarolle === "provider") {
                      if (checkExistUser.checkUser) {
                        
                        dispatch(
                          UpdateCheckExistUser({
                            checkUser: true,
                            path: checkExistUser.path,
                            login: true,
                            checkAcceptUser: true,
                          })
                        );
                        navigate(`${checkExistUser.path}`);
                      } else {
                        navigate("/provider/dashboard");
                      }
                    } else if (datarolle === "worker") {
                      navigate("/worker/dashboard");
                    }
                  }
                  setLoading(false);
                }
              }
            }
            if (trustDevide === true) {
              // const currentData = new Date();
              const current = new Date();
              const currentData = moment(current).format("YYYY-MM-DD");
              const trustData = { currentData, email };
              localStorage.setItem(
                "TrustDeviceDate",
                JSON.stringify(trustData)
              );
            } else {
              localStorage.removeItem("TrustDeviceDate");
            }
            dispatch(setAllFunction(GetAllFunctionData));
            setLoading(false);
            setThreeDotLoader(false);
          }, 500);
        } else {
          notify.show(response?.response?.data.message, "error");
          setLoading(false);
          setThreeDotLoader(false);
        }
      })
      .catch((error) => {
        // Handle the error here
        notify.show(error.message || "An error occurred", "error");
        setLoading(false);
        setThreeDotLoader(false);
      })
      .finally(() => {
        setLoading(false);
        setThreeDotLoader(false);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div
          style={{ backgroundColor: `#eff8ff` }}
          className="flex justify-center min-h-screen"
        >
          <div className="min-h-screen justify-center flex  py-12 px-6">
            <div className="flex-col items-center justify-center flex">
              <div className="flex items-center mb-6">
                <img className="brand-img" src={logoImage} alt="conserve" />
              </div>
              <Heading className="text-center mb-3">
                2 Step-Verification
              </Heading>
              <p className="text-base text-gray-600 text-center mb-6 font-medium">
                We’ve sent a verification code to <strong>{email}</strong>. This
                code will <br /> be valid for 30 minutes.{" "}
              </p>
              <Card className="w-480 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
                <div className="w-full">
                  <form onSubmit={loginSubmitHandler}>
                    <div className="relative flex justify-center gap-2 mb-6">
                      <input
                        ref={(el) => (inputRefs.current[0] = el)}
                        className="text-center text-5xl input-shadow h-16 w-16 font-medium border-gray-300 rounded-lg appearance-none border  text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="text"
                        type="text"
                        // maxLength={1}
                        placeholder="0"
                        value={digit1}
                        onChange={(event) => handleInputChange(0, event)}
                        required
                      />
                      <input
                        ref={(el) => (inputRefs.current[1] = el)}
                        className="text-center text-5xl input-shadow h-16 w-16 font-medium border-gray-300 rounded-lg appearance-none border  text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="text"
                        type="text"
                        // maxLength={1}
                        placeholder="0"
                        value={digit2}
                        onChange={(event) => handleInputChange(1, event)}
                        required
                      />
                      <input
                        ref={(el) => (inputRefs.current[2] = el)}
                        className="text-center text-5xl input-shadow h-16 w-16 font-medium border-gray-300 rounded-lg appearance-none border  text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="text"
                        type="text"
                        // maxLength={1}
                        placeholder="0"
                        value={digit3}
                        onChange={(event) => handleInputChange(2, event)}
                        required
                      />
                      <input
                        ref={(el) => (inputRefs.current[3] = el)}
                        className="text-center text-5xl input-shadow h-16 w-16 font-medium border-gray-300 rounded-lg appearance-none border  text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="text"
                        type="text"
                        // maxLength={1}
                        placeholder="0"
                        value={digit4}
                        onChange={(event) => handleInputChange(3, event)}
                        required
                      />
                    </div>

                    <Radio
                      id="trustDevide"
                      className="items-center justify-center w-full mb-6"
                      checked={!trustDevide ? false : true}
                      onClick={handleTrustDeviceChange}
                    >
                      Trust this device for 15 days{" "}
                    </Radio>

                    <div className="flex items-center justify-between w-100">
                      <BtnPrimary
                        className="flex items-center justify-center"
                        type="submit"
                        disabled={threeDotLoader}
                      >
                        {threeDotLoader ? (
                          <ThreeDotLoader />
                        ) : (
                          <span className="pr-2">Verify and sign in </span>
                        )}
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.167 10.487h11.666m0 0L10 4.654m5.833 5.833L10 16.321"
                            stroke="#fff"
                            stroke-width="1.667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </BtnPrimary>
                    </div>
                  </form>
                </div>
              </Card>
              <div className=" mt-6 px-10 w-full">
                <p className="text-base text-gray-400 text-center font-semibold">
                  Did not receive an email?
                  <a
                    href="#"
                    className="text-blue-700 hover:text-gray-900 focus:text-gray-600 text-sm ml-1"
                  >
                    <button
                      disabled={buttonDisabled}
                      type="submit"
                      onClick={handleRedendEmail}
                    >
                      {buttonDisabled ? <ThreeDotLoader /> : "Resend email"}
                    </button>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onSignInVerify: userActions.SignInVerify,
  onResendSignInOtp: userActions.ResendSignInOtp,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFA);
