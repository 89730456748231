import React, { useEffect, useState } from "react";
import Table from "../../../../common/element/Table";
import { useSelector } from "react-redux";
import Button from "../../../../common/element/Buttons";
import Tag from "../../../../common/element/Tag";
import rightIcon from "../../../../assets/icons-svg/chevron-right.svg";
import moment from "moment";
const CompanyInduction = (props) => {
  const { GetSpecificWorkerIndData } = useSelector((state) => state);
  const [inductionData, setInductionData] = useState([]);
  const [toggleState, setToggleState] = useState(
    Array(inductionData?.length).fill(false)
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const { Tr, Th, Td, THead, TBody } = Table;

  useEffect(() => {
    setInductionData(
      GetSpecificWorkerIndData?.data?.data?.rows
        ? GetSpecificWorkerIndData?.data?.data?.rows
        : []
    );
  }, [GetSpecificWorkerIndData]);

  const openInDialog = (item, company, client_org_id) => {
    props.handleView(item, company, client_org_id);
  };

  // Function to toggle the main rows
  const toggleClick = (index) => {
    const newToggleStates = [...toggleState];
    newToggleStates[index] = !newToggleStates[index];
    setToggleState(newToggleStates);
    // Close all child rows when closing a parent row
    // setExpandedChildRows({});

    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = [...prevExpandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      return newExpandedRows;
    });
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <div className="p-2 mb-6">
          <div className="overflow-hidden border rounded-lg border-gray-200">
            <Table>
              <THead>
                <Tr>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      onClick={() => props.handleSort("trading_name")}
                    >
                      Client Name
                      <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "trading_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Th>

                  <Th>Induction(s)</Th>
                  <Th>Module(s)</Th>
                  <Th>Date completed</Th>
                  <Th>Status</Th>
                </Tr>
              </THead>
              <TBody>
                {inductionData?.map((items, index) => {
                  let clientCompInd = items?.clientAssign?.clientCompInd || [];
                  let progressbar = 0;
                  let length = clientCompInd?.length;
                  let getCompletedInduction = 100 / parseInt(length, 10);
                  let completedLength =
                    clientCompInd?.length &&
                    clientCompInd.filter(
                      (item) =>
                        item?.workerCompInd?.is_comp_ind_completed == true
                    ).length;

                  let totalModuleCount = 0;

                  if (clientCompInd?.length) {
                    totalModuleCount = clientCompInd
                      .filter((item) => item?.companyIndModuleCount)
                      .reduce(
                        (total, item) =>
                          parseInt(total, 10) +
                          parseInt(item.companyIndModuleCount, 10),
                        0
                      );
                  }

                  progressbar = getCompletedInduction * completedLength;
                  console.log("completedLength::", completedLength, length);

                  let completedDate;
                  if (length) {
                    if (length === completedLength) {
                      const completedDates = clientCompInd
                        ?.filter(
                          (item) => item?.workerCompInd?.comp_ind_complete_date
                        ) // Filter out items with a date
                        .map(
                          (item) =>
                            new Date(item.workerCompInd.comp_ind_complete_date)
                        ); // Map to Date objects

                      if (completedDates.length) {
                        const latestDate = completedDates.reduce(
                          (latest, current) => {
                            return current > latest ? current : latest;
                          },
                          new Date(0)
                        ); // Initialize with a very old date

                        completedDate = latestDate;
                      }
                    }
                  }

                  return (
                    <React.Fragment>
                      <Tr
                        key={index}
                        className={"cursor-pointer"}
                        onClick={() => {
                          items?.clientAssign?.clientCompInd?.length == 1 &&
                            openInDialog(
                              items?.clientAssign?.clientCompInd[0],
                              "company",
                              items?.organisation_id
                            );
                        }}
                      >
                        <Td>
                          <div className="flex items-center">
                            {items?.clientAssign?.clientCompInd?.length > 1 && (
                              <Button
                                onClick={() => toggleClick(index)}
                                className={`bg-gray-50 rounded-full h-6 w-6 p-0.5 mr-2 ${
                                  toggleState[index] ? "rotate-90" : ""
                                }`}
                              >
                                <img src={rightIcon} alt="" />
                              </Button>
                            )}
                            <Button
                            // onClick={() => {
                            //   items?.clientAssign?.clientCompInd?.length ==
                            //     1 &&
                            //     openInDialog(
                            //       items?.clientAssign?.clientCompInd[0],
                            //       "company",
                            //       items?.clientAssign?.organisation_id
                            //     );
                            // }}
                            >
                              {items?.trading_name}
                            </Button>
                          </div>
                        </Td>
                        <Td>
                          {items?.clientAssign?.clientCompInd?.length > 1
                            ? items?.clientAssign?.clientCompInd?.length +
                              " " +
                              "Inductions"
                            : items?.clientAssign?.clientCompInd?.[0]
                                ?.company_ind_name}
                        </Td>
                        <Td>{totalModuleCount}</Td>
                        <Td>
                          {items?.clientAssign?.clientCompInd?.length == 1
                            ? items?.clientAssign?.clientCompInd?.[0]
                                ?.workerCompInd?.comp_ind_complete_date
                              ? moment(
                                  items?.clientAssign?.clientCompInd?.[0]
                                    ?.workerCompInd?.comp_ind_complete_date
                                ).format("DD/MM/YYYY")
                              : "-"
                            : length == completedLength
                            ? moment(completedDate).format("DD/MM/YYYY")
                            : "-"}
                        </Td>
                        <Td className={"flex items-center gap-1	"}>
                          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div
                              className="bg-blue-600 h-2.5 rounded-full"
                              style={{ width: `${progressbar?.toFixed(2)}%` }}
                            ></div>
                          </div>
                          {`${progressbar?.toFixed(2)}%`}
                        </Td>
                      </Tr>
                      {expandedRows[index] &&
                        items?.clientAssign?.clientCompInd.map(
                          (innerItem, index) => {
                            // Status
                            let status;
                            if (
                              innerItem?.workerCompInd?.is_comp_ind_completed
                            ) {
                              status = "Completed";
                            } else if (
                              innerItem?.workerCompInd?.is_comp_ind_completed ==
                              false
                            ) {
                              status = "Pending";
                            } else {
                              status = "Awaiting action";
                            }
                            return (
                              <Tr
                                className={"cursor-pointer"}
                                key={index}
                                onClick={() => {
                                  openInDialog(
                                    innerItem,
                                    "company",
                                    items?.organisation_id
                                  );
                                }}
                              >
                                <Td>
                                  <div className="flex items-center">
                                    <span class="h-6 w-6 mr-2 gray-line-set relative"></span>
                                  </div>
                                </Td>
                                <Td>
                                  <Button
                                  // onClick={() => {
                                  //   openInDialog(innerItem, "company",items?.clientAssign?.organisation_id);
                                  // }}
                                  >
                                    {innerItem?.company_ind_name}
                                  </Button>
                                </Td>
                                <Td>{innerItem.companyIndModuleCount}</Td>
                                <Td>
                                  {innerItem?.workerCompInd
                                    ?.comp_ind_complete_date
                                    ? moment(
                                        innerItem?.workerCompInd
                                          ?.comp_ind_complete_date
                                      ).format("DD/MM/YYYY")
                                    : "-"}
                                </Td>
                                <Td>
                                  {status == "Awaiting action" && (
                                    <Tag className=" bg-gray-100 text-gray-700">
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      {status}
                                    </Tag>
                                  )}
                                  {status == "Completed" && (
                                    <Tag className="bg-success-100 text-success-700">
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m10 3.691-5.5 5.5-2.5-2.5"
                                          stroke="#039855"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Completed
                                    </Tag>
                                  )}
                                  {status == "Pending" && (
                                    <Tag className="bg-blue-100 text-blue-800">
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_1592_20677)">
                                          <path
                                            d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                            stroke="#5786CC"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1592_20677">
                                            <rect
                                              width="12"
                                              height="12"
                                              fill="white"
                                              transform="translate(0 0.691406)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      Pending
                                    </Tag>
                                  )}
                                </Td>
                                <Td></Td>
                              </Tr>
                            );
                          }
                        )}
                    </React.Fragment>
                  );
                })}
              </TBody>
            </Table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CompanyInduction;
