import React, { useCallback, useEffect, useState } from "react";
import Card from "../../../../common/element/Card";
import Heading from "../../../../common/element/Heading";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Tooltip from "../../../../common/element/Tooltip";
import Segment from "../../../../common/element/Segment";
import Upload from "../../../../common/element/Upload";
import ThreeDotLoader from "../../../../common/element/ButtonLoader/ThreeDotLoader";
import { CreateOrUpdateProviderRegisTempData } from "../../../../actions/public/CreateOrUpdateProviderRegisTempData";
import * as userActions from "../../../../actions/index";
import { useDispatch } from "react-redux";
import WorkHealth from "./WorkHealth";
import PdfFile from "../../../../assets/icons-svg/upload-pdf.svg";
import YourBusiness from "./YourBusiness";
import { setAllFunction } from "../../../../actions";
import { useSelector } from "react-redux";
import PaymentMethod from "./StepTwo";
import { GetProviderRegisSaveAndContinueById } from "../../../../actions/public/GetProviderRegisSaveAndContinueById";
import Button from "../../../../common/element/Buttons";
import { RegistrationValidationCheck } from "../../../../redux/classes/common/RegistrationValidation";
import Validations from "../../../../common/404/Validations";
import { UpdateProviderRegisTempData } from "../../../../actions/public/UpdateProviderRegisTempData";
import {
  GettData,
  ResetData,
} from "../../../../redux/classes/common/ProviderRegisterData";
import { SignupProviderForYourself } from "../../../../actions/public/SignupProviderForYourself";
import { ResetProviderSignUpData } from "../../../../redux/classes/provider-portal/SignUp";
import { decryptId } from "../../../../common/encryptDecrypt/encryptdecrypt";
const ContractorRegistrationSelf = () => {
  const { ProviderContactData } = useSelector((state) => state);
  const { SignUpData } = useSelector((state) => state);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const providerTempRegistID = decryptId(
  //   searchParams.get("provider_temp_regist_id").replace(/\s/g, "+")
  // );
  const providerTempRegistID = searchParams.get("provider_temp_regist_id")
    ? decryptId(searchParams.get("provider_temp_regist_id").replace(/\s/g, "+"))
    : null;

  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState();

  const [step, Setstep] = useState(false);

  const [msaInfo, setMsaInfo] = useState({
    msa_ques_one:
      "Is your organisation required to report under the Modern Slavery Act (Cth) 2022?*",
    ans_ques_one: "",
    msa_ques_two:
      "Does your organisation have policies to deal with modern slavery?*",
    ans_ques_two: "",
    msa_ques_three:
      "Does your organisation have a person(s) overseeing modern slavery risks?*",
    ans_ques_three: "",
    msa_ques_four:
      "Are staff in your organisation trained to manage modern slavery risks?*",
    ans_ques_four: "",
    msa_ques_five:
      "Does your organisation engage low skilled migrant workers?*",
    ans_ques_five: "",
    msa_ques_six:
      "Does your organisation screen prospective suppliers to assess modern slavery risks?*",
    ans_ques_six: "",
  });

  const [FormData, SetFormData] = useState({
    registrationType: "yourself",
    provider_temp_regist_id: "",
    user_id: "",
    tempMsaDoc: {},
    provider_regist_data: {
      address_data: [
        {
          country_id: "",
          state_id: "",
          address_one: "",
          address_two: "",
          suburb: "",
          zipcode: "",
          address_type: "",
        },
      ],
      individual_data: [],
      whs_contact_info: {
        title: "mr",
        first_name: "",
        last_name: "",
        phone: "",
        phone_optional: "",
        email: "",
      },
      org_type: "other",
      abn_nzbn: "",
      trading_name: "",
      high_risk_master_setting_id: "",
      ind_master_setting_id: "",
      provider_type_id: ProviderContactData?.provider_type_id,
      no_of_emp: "",
      high_risk_activity: true,
      whs_system_available: false,
      whs_system_certified: false,
      msa_info: {},
    },
  });

  const [contactError, SetContactError] = useState({
    ans_ques_one: "",
    ans_ques_two: "",
    ans_ques_three: "",
    ans_ques_four: "",
    ans_ques_five: "",
    ans_ques_six: "",
  });

  const msaInfoHandle = (val, field) => {
    SetContactError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: "",
    }));
    setMsaInfo((prevFormData) => ({
      ...prevFormData,
      [field]: val[0],
    }));
  };

  const handleFileChange = (files, name) => {
    // client_engagement_doc
    SetFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
    // setAttachment(files);
  };

  const handleFileRemove = (files, name) => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const UpdateData = (data, contact) => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        individual_data: contact, // Update the contact information
        address_data: data.provider_address_data,
        ...data.detailsData,
      },
    }));
  };

  const UpdateWHSData = (data, WHSData) => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        whs_contact_info: data, // Update the contact information
        ...WHSData,
      },
    }));
  };

  useEffect(() => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        msa_info: msaInfo, // Update the contact information
      },
    }));
  }, [msaInfo]);

  const setFormError = (field, message) => {
    SetContactError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };
  const SubmitData = () => {
    if (ProviderRegisterData?.data == null) {
      if (true) {
        setButtonDisabled2(true)
        dispatch(
          CreateOrUpdateProviderRegisTempData(
            FormData,
            SignUpData?.registrationType
          )
        )
          .then((response) => {
            if (response.success) {
              setButtonDisabled2(false);
              navigate("/login");
            } else {
              console.error("Error:", response.message || "Unknown error occurred.");
            }
          })
          .catch((error) => {
            console.error("An error occurred during registration:", error);
          })
          .finally(() => {
            setButtonDisabled2(false);
          });

      }
    } else {
      setButtonDisabled2(true)
      dispatch(UpdateProviderRegisTempData(FormData))
        .then((response) => {
          if (response.success) {
            navigate("/login");
            setButtonDisabled2(false);
          } else {
            console.error("Error:", response.message || "Unknown error occurred.");
          }
        })
        .catch((error) => {
          console.error("An error occurred during the update:", error);
        })
        .finally(() => {
          setButtonDisabled2(false);
        });

    }
  };

  const finalSaveData = () => {
    // setDialogIsOpen(true);
    dispatch(RegistrationValidationCheck(true));

    // MSA Validation
    // Function to check if all answers are not empty
    function checkAllAnswersNotEmpty(data) {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (data[key] === "") {
            return false;
          }
        }
      }
      return true;
    }
    const allAnswersNotEmpty = checkAllAnswersNotEmpty(
      FormData?.provider_regist_data?.msa_info
    );

    // address_data Validation
    const isValidAddress = (address) => {
      return Object.keys(address).every(
        (key) => key === "address_two" || address[key] !== ""
      );
    };

    const results =
      FormData?.provider_regist_data?.address_data.map(isValidAddress);

    // Details Validation
    let detailsData = {
      abn_nzbn: FormData?.abn_nzbn,
      no_of_emp: FormData?.no_of_emp,
      ind_master_setting_id: FormData?.ind_master_setting_id,
      trading_name: FormData?.trading_name,
    };
    const allDetailsDataNotEmpty = Object.values(detailsData).every(
      (value) => value !== ""
    );

    // address_data Validation
    const PrimaryContact = (address) => {
      return Object.keys(address).every(
        (key) => key === "address_two" || address[key] !== ""
      );
    };

    const PrimaryContactResult =
      FormData?.provider_regist_data?.individual_data.map(PrimaryContact);

    const ans_ques_one = Validations({
      value: msaInfo.ans_ques_one,
      Validations: { required: true },
    });

    const ans_ques_two = Validations({
      value: msaInfo.ans_ques_two,
      Validations: { required: true },
    });

    const ans_ques_three = Validations({
      value: msaInfo.ans_ques_three,
      Validations: { required: true },
    });

    const ans_ques_four = Validations({
      value: msaInfo.ans_ques_four,
      Validations: { required: true },
    });

    const ans_ques_five = Validations({
      value: msaInfo.ans_ques_five,
      Validations: { required: true },
    });

    const ans_ques_six = Validations({
      value: msaInfo.ans_ques_six,
      Validations: { required: true },
    });

    if (!ans_ques_one.valid) {
      setFormError("ans_ques_one", ans_ques_one.error_msg);
    }

    if (!ans_ques_two.valid) {
      setFormError("ans_ques_two", ans_ques_two.error_msg);
    }

    if (!ans_ques_three.valid) {
      setFormError("ans_ques_three", ans_ques_three.error_msg);
    }

    if (!ans_ques_four.valid) {
      setFormError("ans_ques_four", ans_ques_four.error_msg);
    }

    if (!ans_ques_five.valid) {
      setFormError("ans_ques_five", ans_ques_five.error_msg);
    }

    if (!ans_ques_six.valid) {
      setFormError("ans_ques_six", ans_ques_six.error_msg);
    }
    const abnValidation = Validations({
      value: FormData?.provider_regist_data?.abn_nzbn,
      Validations: { required: true, ValidateABN: true },
    });
    if (
      // FormData?.high_risk_master_setting_id &&
      abnValidation.valid &&
      ans_ques_six.valid &&
      ans_ques_five.valid &&
      ans_ques_four.valid &&
      ans_ques_three.valid &&
      ans_ques_two.valid &&
      ans_ques_one.valid &&
      allAnswersNotEmpty &&
      results &&
      allDetailsDataNotEmpty &&
      PrimaryContactResult
    ) {
      setButtonDisabled(true)
      dispatch(SignupProviderForYourself(FormData))
        .then((response) => {
          if (response.success) {
            navigate("/login");
            setButtonDisabled(false);
            dispatch(ResetProviderSignUpData());
          } else {
            console.error("Signup failed:", response.message || "Unknown error occurred.");
          }
        })
        .catch((error) => {
          console.error("An error occurred during signup:", error);
        })
        .finally(() => {
          setButtonDisabled(false);
        });

    }
  };

  // Get Registration Data
  const { ProviderRegisterData } = useSelector((state) => state);

  useEffect(() => {
    if (providerTempRegistID != null) {
      dispatch(
        GetProviderRegisSaveAndContinueById({
          provider_temp_regist_id: providerTempRegistID,
        })
      ).then((response) => {
        if (response?.success) {
          if (response?.data == null) {
            navigate("/expire-link");
            dispatch(ResetData());
          } else {
            if (response?.data?.is_regis_completed) {
              dispatch(ResetData());
              navigate("/login");
            } else {
              dispatch(GettData(response));
              SetFormData((prevalue) => {
                return {
                  ...prevalue,
                  ...response?.data,

                  ["tempMsaDoc"]: response?.data?.temp_msa_doc,
                };
              });
              setMsaInfo((prevalue) => {
                return {
                  ...prevalue,
                  ...response?.data?.provider_regist_data?.msa_info,
                };
              });
            }
          }
        } else {
          dispatch(ResetData());
        }
      });
    } else {
      dispatch(ResetData());
    }
  }, [ProviderRegisterData?.success]);

  useEffect(() => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        individual_data: SignUpData, // Update the contact information
      },
    }));
  }, [SignUpData]);

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-between pr-6 pl-6"
    >
      <div className="w-full  justify-center flex flex-wrap">
        <div className="sideBarStepWrap sticky top-0  pt-20 flex justify-between flex-col pb-20">
          <div className="block">
            <div className="flex items-center mb-6">
              <img src="/assets/logo.svg" alt="conserve" width="149px" />
            </div>
            <Heading className="font-bold mb-10 text-2xl">
              Registering as a Contractor for yourself{" "}
            </Heading>
            <ul className="stepListWrap mb-20">
              {step ? (
                <li className="mb-6 step-cmpt">
                  <div className="flex">
                    <span className="flex h-8 w-8 bg-blue-100 rounded-full items-center justify-center mr-4">
                      <img src="assets/icons-svg/checkMark.svg" alt="" />
                    </span>
                    <div>
                      <h6 className="flex text-gray-700 font-semibold mb-0.5">
                        <span className="mr-0.5">1.</span> Business Information{" "}
                      </h6>
                      <p className=" text-gray-600">
                        Tell us about your business
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                <li className="mb-6 active">
                  <div className="flex">
                    <span className="flex h-8 w-8 bg-white rounded-full outline-4 outline-blue-200 items-center justify-center mr-4 outline">
                      <span className="inline-block h-2.5 w-2.5 bg-blue-800 rounded-full"></span>
                    </span>
                    <div>
                      <h6 className="flex text-blue-800 font-semibold mb-0.5">
                        <span className="mr-0.5">1.</span> Business Information{" "}
                      </h6>
                      <p className="text-blue-700">
                        Tell us about your business
                      </p>
                    </div>
                  </div>
                </li>
              )}
              <li className="mb-6">
                <div className="flex">
                  {step ? (
                    <span className="flex h-8 w-8 bg-white rounded-full outline-4 outline-blue-200 items-center justify-center mr-4 outline">
                      <span className="inline-block h-2.5 w-2.5 bg-blue-800 rounded-full"></span>
                    </span>
                  ) : (
                    <span
                      className={`flex items-center justify-center h-8 w-8 bg-gray-300 rounded-full mr-4`}
                    >
                      <span
                        className={`inline-block h-2.5 w-2.5 bg-gray-400 rounded-full`}
                      ></span>
                    </span>
                  )}
                  <div>
                    <h6 className={`flex text-gray-700 font-semibold mb-0.5`}>
                      <span className="mr-0.5">2.</span>Payment
                    </h6>
                    <p className={`text-gray-600`}>
                      Securely complete your registration by <br />
                      making a payment.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex pr-20 gap-4">
            <Link
              to="/login"
              className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex back-btn-hide items-center"
            >
              {" "}
              <img
                src="assets/icons-svg/arrow-left.svg"
                className="w-5 mr-2"
                alt=""
              />{" "}
              Back to log in
            </Link>
            <BtnLight className="w-auto px-3 text-xs">
              Save and continue another time
            </BtnLight>
          </div>
        </div>
        {step && <PaymentMethod />}
        {!step && (
          <form className="pb-20 pt-20">
            <Card className="registerBlock p-7 border border-gray-200 rounded-xl">
              <YourBusiness UpdateData={UpdateData} />
            </Card>
            <Card className="registerBlock p-7 mt-7 border border-gray-200 rounded-xl">
              <WorkHealth UpdateData={UpdateWHSData} />
            </Card>
            <Card className="registerBlock p-7 border border-gray-200 rounded-xl mt-7 ">
              <div className="flex justify-between flex-col mb-5 pt-5">
                <h5 className="text-blue-800 font-semibold text-2xl">
                  Modern Slavery Act (Cth) 2022{" "}
                  <Tooltip
                    title="Modern Slavery Act (Cth) 2022"
                    placement="top-start"
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.06 6.5a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.833h.007m6.66-3.333a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0z"
                        stroke="#98A2B3"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Tooltip>
                </h5>
              </div>
              <div className="items-start  mb-5">
                <p className="font-semibold w-full font-sm mb-5">
                  Is your organisation required to report under the Modern
                  Slavery Act (Cth) 2022*?
                </p>
                <div className="flex items-center  validation-filter ">
                  <Segment
                    value={`${FormData?.provider_regist_data?.msa_info.ans_ques_one}`}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_one")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
                {contactError.ans_ques_one && (
                  <p className="text-xs text-red-600 mb-4 mt-0">
                    {contactError.ans_ques_one}
                  </p>
                )}
              </div>
              {/* {hideCheckList && ( */}
              <div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation have policies to deal with modern
                    slavery?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_two}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_two")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_two && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {contactError.ans_ques_two}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation have a person(s) overseeing modern
                    slavery risks?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_three}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_three")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_three && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {contactError.ans_ques_three}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Are staff in your organisation trained to manage modern
                    slavery risks?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_four}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_four")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_four && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {contactError.ans_ques_four}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation engage low skilled migrant workers?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_five}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_five")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_five && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {contactError.ans_ques_five}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation screen prospective suppliers to
                    assess modern slavery risks?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_six}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_six")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_six && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {contactError.ans_ques_six}
                    </p>
                  )}
                </div>
                <div className="items-start mb-5">
                  <h4 className="font-bold text-gray-700 text-base mb-5">
                    Attach The Modern Safety Statement
                  </h4>
                  <div className="Pflex items-center  flex-1 validation-filter ">
                    <div className="columns-input">
                      <div className="hideIcons wrap-doc">
                        {!FormData?.tempMsaDoc?.endsWith?.(".pdf") ? (
                          <Upload
                            draggable
                            accept={".pdf"}
                            uploadLimit={1}
                            className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                            onChange={(e) => handleFileChange(e, "tempMsaDoc")}
                            onFileRemove={(e) =>
                              handleFileRemove(e, "tempMsaDoc")
                            }
                            maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
                          >
                            <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
                              <img
                                src="/assets/icons-svg/upload.svg"
                                alt=""
                                className="w-4"
                              />
                            </div>
                            <span className="text-blue-700 font-semibold">
                              Click to upload
                            </span>{" "}
                            <span className="text-xs"> or drag and drop</span>{" "}
                            <p className=" text-gray-600 darks:text-gray-400 font-inter">
                              PDF only. Max 25mb.
                            </p>
                          </Upload>
                        ) : (
                          <div className="p-4 rounded-xl border border-gray-300 w-full flex items-center justify-between">
                            <div className="flex  gap-4  items-center">
                              <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                                <img
                                  src="/assets/icons-svg/upload-pdf.svg"
                                  alt=""
                                />
                              </span>
                              <div className="flex flex-col  items-center">
                                <span className="text-gray-700 text-xs font-medium">
                                  {FormData.tempMsaDoc}
                                  {/* {checkPdf?.name} */}
                                </span>
                                {/* <span className="text-gray-600 text-xs">
                  {checkPdf?.size
                      ? `${(checkPdf?.size / 1024 / 1024).toFixed(2)} MB`
                      : `${(props.size.size / 1024).toFixed(2)} MB`}
                  </span> */}
                              </div>
                            </div>
                            <Button
                              onClick={(e) => handleFileRemove(e, "tempMsaDoc")}
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#344054"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </div>
                        )}

                        {uploadedFiles && uploadedFiles.files && (
                          <div className="upload-file flex items-center justify-between mt-2">
                            <div className="upload-file-left flex items-center">
                              <div className="upload-file-thumbnail">
                                <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                                  <img src={PdfFile} alt="" />
                                </span>
                              </div>
                              <a
                                href={uploadedFiles.files[0].file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="upload-file-name font-medium text-xs text-gray-700">
                                  {uploadedFiles.files[0].fileName}
                                </span>
                              </a>
                            </div>
                            {/* <span className="upload-file-remove w-5" onClick={() => handleRemoveFile(item)}> */}
                            {/* <span className=""><img src={trashIcon} alt="" /></span>
                          </span> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className="flex mt-7 justify-end form-footer">
              <BtnLight disabled={buttonDisabled || buttonDisabled2} className="w-auto px-6 mr-2 text-black" onClick={SubmitData}>
                {/* {(buttonDisabled2) ? <ThreeDotLoader /> : " Save and continue another time"} */}
                Save and continue another time
              </BtnLight>

              <BtnPrimary disabled={buttonDisabled || buttonDisabled2} className="w-auto" onClick={finalSaveData}>
                <div
                  className="flex justify-center items-center px-6"
                  to="step-two"
                >
                  {(buttonDisabled) ? <ThreeDotLoader /> : "Next"}
                  <img
                    src="assets/icons-svg/arrow-right.svg"
                    className="ml-2"
                    alt=""
                  />
                </div>
              </BtnPrimary>
            </div>
            <div className="flex justify-center mt-6 show-on-mobile">
              <Link
                to="/login"
                className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex  items-center"
              >
                {" "}
                Back to log in
              </Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
export default ContractorRegistrationSelf;
