import React, { useState, useEffect } from "react"
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { setPermissionData } from "../../../../../../redux/classes/client-portal/permissionsSlice";
import Switcher from "../../../../../../common/element/Switcher";
import ScrollBar from "../../../../../../common/element/ScrollBar/ScrollBar";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { notify } from "react-notify-toast";
// import {UpdateClientUserPermForClient} from '../../../../../../actions/client-portal/UpdateUserForClient'
import { UpdateClientUserPermForClient, GetClientUserPermByIdForClient } from "../../../../../../actions/client-portal/AllUsersApi";
import { useDispatch } from "react-redux";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";

const EditUser = (props) => {
  const dispatch = useDispatch()
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [open, setOpen] = useState(0);
  const [readOnlyAll, setReadOnlyAll] = useState(false)
  const [allowAll, setAllowAll] = useState(false);
  const [allPermissionsTrue, setAllPermissionsTrue] = useState(false);
  const [permission, setPermissions] = useState({
    compliance_view: false,
    compliance_write: false,
    contacts_view: false,
    contacts_write: false,
    provider_view: false,
    provider_write: false,
    induction_view: false,
    induction_write: false,
    invoices_view: false,
    invoices_write: false,
    reporting_view: false,
    reporting_write: false,
    settings_view: false,
    settings_write: false,
    sites_view: false,
    sites_write: false,
    users_view: false,
    users_write: false,
    messages_view: false,
    messages_write: false,
    dashboard_view: false,
    dashboard_write: false,
    notes_view: false,
    notes_write: false,
    support_view: false,
    support_write: false,
  });
  const requiredKeys = [
    'compliance_view',
    'compliance_write',
    'contacts_view',
    'contacts_write',
    'provider_view',
    'provider_write',
    'induction_view',
    'induction_write',
    'invoices_view',
    'invoices_write',
    'reporting_view',
    'reporting_write',
    'settings_view',
    'settings_write',
    'sites_view',
    'sites_write',
    'users_view',
    'users_write',
    'messages_view',
    'messages_write',
    'dashboard_view',
    'dashboard_write',
    'notes_view',
    'notes_write',
    'support_view',
    'support_write',
  ];

  const areAllPermissionsTrue = (permData) => {
    // Check if permData is an object and has keys
    if (typeof permData !== 'object' || permData === null || Object.keys(permData).length === 0) {
      return false;
    }
    // Filter and reduce to get the relevant permissions
    const filtered = Object.keys(permData)
      .filter(key => requiredKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = permData[key];
        return obj;
      }, {});

    // Check if all values in filtered are true
    return Object.values(filtered).every(value => value === true);
  };


  useEffect(() => {
    if (props?.permData) {
      let filtered = {};
      if (Object.keys(props.permData)?.length > 0) {
        filtered = Object.keys(props.permData)
          .filter(key => requiredKeys?.includes(key))
          .reduce((obj, key) => {
            obj[key] = props.permData[key];
            return obj;
          }, {});
      }
  
      setPermissions(filtered);
  
      const checkAllTrue = areAllPermissionsTrue(filtered);
      setAllPermissionsTrue(checkAllTrue);
      setAllowAll(checkAllTrue);
  
      // View and Write keys
      const viewKeys = [
        'compliance_view', 'contacts_view', 'provider_view', 'induction_view', 'invoices_view',
        'reporting_view', 'settings_view', 'sites_view', 'users_view', 'messages_view',
        'dashboard_view', 'notes_view', 'support_view'
      ];
      const writeKeys = [
        'compliance_write', 'contacts_write', 'provider_write', 'induction_write', 'invoices_write',
        'reporting_write','settings_write','sites_write', 'users_write','messages_write',
        'dashboard_write', 'notes_write','support_write'
      ];
  
      // Check if all view permissions are true
      const areAllViewsTrue = viewKeys.every(key => filtered[key] === true);
      // Check if all write permissions are false
      const areAllWritesFalse = writeKeys.every(key => filtered[key] === false);
  
      // Set ReadOnlyAll to true if the condition is met
      if (areAllViewsTrue && areAllWritesFalse) {
        setReadOnlyAll(true);
      }
    }
  }, [props?.permData]);
  


  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  // Function to handle toggling the Switcher component for view setting
  const onSwitcherToggle = (e, identifier) => {
    setPermissions({ ...permission, [identifier]: !e });
    if (identifier === "settings_view_write") {
      setPermissions({ ...permission, settings_view: !e, settings_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, settings_view: !e, settings_write: !e, }))
    }
    if (identifier === "compliance_view_write") {
      setPermissions({ ...permission, compliance_view: !e, compliance_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, compliance_view: !e, compliance_write: !e, }))
    }
    if (identifier === "users_view_write") {
      setPermissions({ ...permission, users_view: !e, users_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, users_view: !e, users_write: !e, }))
    }
    if (identifier === "contacts_view_write") {
      setPermissions({ ...permission, contacts_view: !e, contacts_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, contacts_view: !e, contacts_write: !e, }))
    }
    if (identifier === "invoices_view_write") {
      setPermissions({ ...permission, invoices_view: !e, invoices_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, invoices_view: !e, invoices_write: !e, }))
    }
    if (identifier === "induction_view_write") {
      setPermissions({ ...permission, induction_view: !e, induction_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, induction_view: !e, induction_write: !e, }))
    }
    if (identifier === "contractor_view_write") {
      setPermissions({ ...permission, provider_view: !e, provider_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, provider_view: !e, provider_write: !e, }))
    }
    if (identifier === "reporting_view_write") {
      setPermissions({ ...permission, reporting_view: !e, reporting_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, reporting_view: !e, reporting_write: !e, }))
    }
    if (identifier === "sites_view_write") {
      setPermissions({ ...permission, sites_view: !e, sites_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, sites_view: !e, sites_write: !e, }))
    }
    if (identifier === "messages_view_write") {
      setPermissions({ ...permission, messages_view: !e, messages_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, messages_view: !e, messages_write: !e, }))
    }
    if (identifier === "dashboard_view_write") {
      setPermissions({ ...permission, dashboard_view: !e, dashboard_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, dashboard_view: !e, dashboard_write: !e, }))
    }
    if (identifier === "notes_view_write") {
      setPermissions({ ...permission, notes_view: !e, notes_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, notes_view: !e, notes_write: !e, }))
    }
    if (identifier === "support_view_write") {
      setPermissions({ ...permission, support_view: !e, support_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, support_view: !e, support_write: !e, }))
    }
    if (identifier === "all") {
      setAllowAll((e) => !e);
      setReadOnlyAll(false);
      setPermissions({
        ...permission,
        compliance_view: !e,
        compliance_write: !e,
        contacts_view: !e,
        contacts_write: !e,
        provider_view: !e,
        provider_write: !e,
        induction_view: !e,
        induction_write: !e,
        invoices_view: !e,
        invoices_write: !e,
        reporting_view: !e,
        reporting_write: !e,
        settings_view: !e,
        settings_write: !e,
        sites_view: !e,
        sites_write: !e,
        users_view: !e,
        users_write: !e,
        messages_view: !e,
        messages_write: !e,
        dashboard_view: !e,
        dashboard_write: !e,
        notes_view: !e,
        notes_write: !e,
        support_view: !e,
        support_write: !e,
      })
      setAllPermissionsTrue(prevState => !prevState)
    }
    if (identifier === "realAll") {
      if (allowAll == true) {
        setAllPermissionsTrue(prevState => !prevState)
      }
      setReadOnlyAll(!readOnlyAll); // Toggle readOnlyAll state
      setAllowAll(false);
      setPermissions({
        ...permission,
        compliance_view: !readOnlyAll,
        compliance_write: false,
        contacts_view: !readOnlyAll,
        contacts_write: false,
        provider_view: !readOnlyAll,
        provider_write: false,
        induction_view: !readOnlyAll,
        induction_write: false,
        invoices_view: !readOnlyAll,
        invoices_write: false,
        reporting_view: !readOnlyAll,
        reporting_write: false,
        settings_view: !readOnlyAll,
        settings_write: false,
        sites_view: !readOnlyAll,
        sites_write: false,
        users_view: !readOnlyAll,
        users_write: false,
        messages_view: !readOnlyAll,
        messages_write: false,
        dashboard_view: !readOnlyAll,
        dashboard_write: false,
        notes_view: !readOnlyAll,
        notes_write: false,
        support_view: !readOnlyAll,
        support_write: false
      });
    }
  };
  const UpdateUser = (e) => {
    const payload = {
      fa_user_permission_id: props?.permData?.fa_user_permission_id,
      compliance_view: permission?.compliance_write ? true : permission?.compliance_view,
      compliance_write: permission?.compliance_write,

      contacts_view: permission?.contacts_write ? true : permission?.contacts_view,
      contacts_write: permission?.contacts_write,

      provider_view: permission?.provider_write ? true : permission?.provider_view,
      provider_write: permission?.provider_write,

      induction_view: permission?.induction_write ? true : permission?.induction_view,
      induction_write: permission?.induction_write,

      invoices_view: permission?.invoices_write ? true : permission?.invoices_view,
      invoices_write: permission?.invoices_write,

      reporting_view: permission?.reporting_write ? true : permission?.reporting_view,
      reporting_write: permission?.reporting_write,

      settings_view: permission?.settings_write ? true : permission?.settings_view,
      settings_write: permission?.settings_write,

      sites_view: permission?.sites_write ? true : permission?.sites_view,
      sites_write: permission?.sites_write,

      users_view: permission?.users_write ? true : permission?.users_view,
      users_write: permission?.users_write,

      messages_view: permission?.messages_write ? true : permission?.messages_view,
      messages_write: permission?.messages_write,

      dashboard_view: permission?.dashboard_write ? true : permission?.dashboard_view,
      dashboard_write: permission?.dashboard_write,

      notes_view: permission?.notes_write ? true : permission?.notes_view,
      notes_write: permission?.notes_write,

      support_view: permission?.support_write ? true : permission?.support_view,
      support_write: permission?.support_write,

    };
    props?.setButtonDisabled(true)
    dispatch(UpdateClientUserPermForClient(payload))
      .then((response) => {
        if (response?.success) {
          props?.setButtonDisabled(false)
          props?.closeUserEdit();
          // const apiData = {
          //   user_id: props?.userIdSpecifice?.user_id,
          //   function_assignment_id: props?.userIdSpecifice?.function_assignment_id
          // };
          // dispatch(GetClientUserPermByIdForClient(apiData))
          //   .then((response) => {
          //     if (response?.success) {
          //       // dispatch(setPermissionData(response?.data));
          //        // Store data in Redux store
          //     }
          //   })
          //   .catch((error) => {

          //   });

        }
      })
      .catch((error) => {
        // Handle error if needed
      }).finally(() => {
        props?.setButtonDisabled(false)
      })



  };
  return (
    <React.Fragment>
      <div className="h-500">
        <ScrollBar>
          <div className="px-6 pb-3">
            <div className="flex flex-col">
              <span className="text-base text-gray-900 font-semibold">User access</span>
              <p className="text-gray-600 mt-1">Grant the user full app access or customise their access to specific areas.</p>
            </div>
            {/* 
            <div className="flex items-center mt-7 mb-5 px-4">
              <Switcher onChange={(e) => onSwitcherToggle(e, "all")}
                checked={allPermissionsTrue}
              />
              <span className="text-xs text-gray-700 font-medium ml-2.5">Allow access to all</span>
            </div> */}
            <div className="flex justify-between gap-4 mb-5 px-4 mt-5">
              <div className="flex items-center w-1/2">
                <Switcher onChange={(e) => onSwitcherToggle(e, "all")} checked={allowAll && allPermissionsTrue} />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  {allowAll ? "Full decline to all sections" : "Full access to all sections"}
                </span>
              </div>
              <div className="flex items-center w-1/2">
                <Switcher onChange={(e) => onSwitcherToggle(e, "realAll")} checked={readOnlyAll} />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  {readOnlyAll ? "Read-only decline to all sections" : "Read-only access to all sections"}
                </span>
              </div>
            </div>

            <Accordion open={open === 11}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(11)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "dashboard_view_write")}
                    checked={permission && permission?.dashboard_view && permission?.dashboard_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Dashboard
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "dashboard_view")}
                      checked={permission?.dashboard_view}
                      value={permission?.dashboard_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "dashboard_write")}
                      checked={permission?.dashboard_write}
                      value={permission?.dashboard_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 1}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(1)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "settings_view_write")}
                    checked={permission && permission?.settings_view && permission?.settings_write ? true : false}
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Company Settings
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "settings_view")}
                      checked={permission?.settings_view}
                      value={permission?.settings_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "settings_write")}
                      checked={permission?.settings_write}
                      value={permission?.settings_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(2)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "compliance_view_write")}
                    checked={permission && permission?.compliance_view && permission?.compliance_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Compliance Checklist
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "compliance_view")}
                      checked={permission?.compliance_view}
                      value={permission?.compliance_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "compliance_write")}
                      checked={permission?.compliance_write}
                      value={permission?.compliance_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(3)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "users_view_write")}
                    checked={permission && permission?.users_view && permission?.users_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Users
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "users_view")}
                      checked={permission?.users_view}
                      value={permission?.users_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "users_write")}
                      checked={permission?.users_write}
                      value={permission?.users_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 4}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(4)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "contacts_view_write")}
                    checked={permission && permission?.contacts_view && permission?.contacts_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Contacts
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "contacts_view")}
                      checked={permission?.contacts_view}
                      value={permission?.contacts_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "contacts_write")}
                      checked={permission?.contacts_write}
                      value={permission?.contacts_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 5}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(5)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "invoices_view_write")}
                    checked={permission && permission?.invoices_view && permission?.invoices_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Invoices
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "invoices_view")}
                      checked={permission?.invoices_view}
                      value={permission?.invoices_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "invoices_write")}
                      checked={permission?.invoices_write}
                      value={permission?.invoices_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 6}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(6)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "induction_view_write")}
                    checked={permission && permission?.induction_view && permission?.induction_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Induction
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "induction_view")}
                      checked={permission?.induction_view}
                      value={permission?.induction_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "induction_write")}
                      checked={permission?.induction_write}
                      value={permission?.induction_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 7}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(7)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "contractor_view_write")}
                    checked={permission && permission?.provider_view && permission?.provider_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Provider
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "provider_view")}
                      checked={permission?.provider_view}
                      value={permission?.provider_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "provider_write")}
                      checked={permission?.provider_write}
                      value={permission?.provider_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 8}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(8)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "sites_view_write")}
                    checked={permission && permission?.sites_view && permission?.sites_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Sites
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "sites_view")}
                      checked={permission?.sites_view}
                      value={permission?.sites_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "sites_write")}
                      checked={permission?.sites_write}
                      value={permission?.sites_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 9}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(9)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "reporting_view_write")}
                    checked={permission && permission?.reporting_view && permission?.reporting_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Reports
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "reporting_view")}
                      checked={permission?.reporting_view}
                      value={permission?.reporting_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "reporting_write")}
                      checked={permission?.reporting_write}
                      value={permission?.reporting_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 10}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(10)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "messages_view_write")}
                    checked={permission && permission.messages_view && permission.messages_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Messages
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "messages_view")}
                      checked={permission.messages_view}
                      value={permission.messages_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "messages_write")}
                      checked={permission.messages_write}
                      value={permission.messages_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 12}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(12)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "notes_view_write")}
                    checked={permission && permission?.notes_view && permission?.notes_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Notes
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "notes_view")}
                      checked={permission?.notes_view}
                      value={permission?.notes_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "notes_write")}
                      checked={permission?.notes_write}
                      value={permission?.notes_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 13}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(13)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "support_view_write")}
                    checked={permission && permission?.support_view && permission?.support_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Support
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "support_view")}
                      checked={permission?.support_view}
                      value={permission?.support_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "support_write")}
                      checked={permission?.support_write}
                      value={permission?.support_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
          </div>
        </ScrollBar>


      </div>
      <div className="flex dailog-footer mt-0">
        <BtnLight disabled={props?.buttonDisabled} className="mr-1.5" onClick={props?.closeUserEdit}>Cancel</BtnLight>
        <SmallButton disabled={props?.buttonDisabled} className="ml-1.5" onClick={UpdateUser}>
          {props?.buttonDisabled == false ? "Update user" : "Updaing..."}
        </SmallButton>
      </div>
    </React.Fragment>
  )
}
export default EditUser