import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import Table from "../../../../../../../common/element/Table";
import Button from "../../../../../../../common/element/Buttons";
import Tooltip from "../../../../../../../common/element/Tooltip";
import SmallButton from "../../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
// import { notify } from "react-notify-toast";
import * as userActions from "../../../../../../../actions";
import { useParams } from "react-router-dom";
import moment from "moment";
import helpCircle from "../../../../../../../assets/icons-svg/help-circle.svg";
import userImg from "../../../../../../../assets/icons-svg/user.svg";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { RemoveOrgUser } from "../../../../../../../actions/Admin-Client/Admin-Client";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";
const Users = (props) => {

  const { Tr, Th, Td, THead, TBody } = Table;
  const { id } = useParams();
  const orgId=decryptId(id)
  
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [dialogDeleteOpen, setDeleteIsOpen] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  // const [open, setOpen] = useState(0);
  const [userIdSpecifice, setUserIdSpecifice] = useState()
  const [userDelete, setDelete] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [invitedUser, setInvitedUser] = useState("");
  const [allUser, setAllUser] = useState("");
  const [contactTypeId, setContactTypeId] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [permData, setPermData] = useState("");
  const [inviteId, setInviteId] = useState("");
  const [deleteUserData, setDeleteUserData] = useState("");
  // Retrieve functionAssignmentId from Redux store
  const functionAssignmentId = useSelector(
    (state) => state.functionAssignmentId.functionAssignmentId
  );
  // Retrieve AllContactTypes from Redux store
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  // Effect to set contactTypeId when AllContactType is available
  useEffect(() => {
    if (AllContactType) {
      const contactTypeId = AllContactType.find(
        (item) => item?.contact_type === "client contact"
      )?.contact_type_id;

      setContactTypeId(contactTypeId);
    }
  }, []);
  // Function to open the "Add User" dialog
  const addUserOpen = () => {
    setAddUser(true);
  };
  // Function to close the "Add User" dialog and fetch updated data
  const addUserClose = () => {
    setAddUser(false);
    // GetAllSpecificOrgUsers();
    GetAllSpecificOrgInvites();
  };

  // Function to open the "Remove Invitation" dialog
  const openDialog = () => {
    setIsOpen(true);
  };
  // Function to close the "Remove Invitation" confirmation dialog
  const onDialogClose = () => {
    setIsOpen(false);
  };
  // Function to open the "Delete User" dialog
  const openDeleteDialog = (e) => {
    setDeleteIsOpen(true);
    setInviteId(e.inviteId)
  };
  // Function to close the "Delete User" confirmation dialog
  const closeInvitation = () => {
    if (buttonDisabled == false) {
      setDeleteIsOpen(false);
    }
  };
  // Function to open the "Edit User Access" dialog
  const openUserEdit = (val) => {
    setUserEdit(true);
    GetOrgUserPermById(val?.userId);
    setNameUser(val?.name);
  };
  // Function to close the "Edit User Access" dialog
  const closeUserEdit = () => {
    if (buttonDisabled == false) {
      setUserEdit(false);
    }
  };
  // Function to open the "Delete User" dialog
  const deleteUserDialog = (e) => {
    setDelete(true);
    setDeleteUserData(e)
  };
  // Function to close the "Delete User" dialog
  const closeDeleteUser = () => {
    if (buttonDisabled == false) {
      setDelete(false);
    }
  };

  const GetOrgUserPermById = useCallback(
    async (userId) => {
      dispatch(
        userActions.GetOrgUserPermById(userId, functionAssignmentId)
      ).then((response) => {
        if (response?.success === true) {
          let userId = {
            user_id: userId,
            function_assignment_id: functionAssignmentId
          }
          setUserIdSpecifice(userId)
          const arrayData = response?.data;
          setPermData(arrayData);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const GetAllSpecificOrgUsers = useCallback(async () => {
    if (orgId) {
      dispatch(userActions.GetAllSpecificOrgUsers(orgId)).then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          let client_data = [];
          for (let key in arrayData) {
            const currentTime = moment();
            let ActiveDate =
              arrayData[key]?.org_individual?.user_data?.last_login;
            let active = moment.duration(currentTime.diff(ActiveDate));
            client_data.push({
              email: arrayData[key]?.org_individual?.email,
              name:
                arrayData[key]?.org_individual?.first_name +
                " " +
                arrayData[key]?.org_individual?.last_name,

              individualId: arrayData[key]?.org_individual?.individual_id,
              userId: arrayData[key]?.org_individual?.user_data?.user_id,
              individualOrgId: arrayData[key]?.individual_org_id,
              activeDate: arrayData[key]?.org_individual?.user_data?.last_login
                ? active.asSeconds() < 60
                  ? "Just now"
                  : active.asMinutes() < 60
                    ? `${Math.floor(active.asMinutes())} ${active.asMinutes() === 1 ? "minute" : "minutes"
                    } ago`
                    : active.asHours() < 24
                      ? `${Math.floor(active.asHours())} ${active.asHours() === 1 ? "hour" : "hours"
                      } ago`
                      : active.asDays() < 2
                        ? "Yesterday"
                        : `${Math.floor(active.asDays())} ${active.asDays() === 1 ? "day" : "days"
                        } ago`
                : "",
            });
          }
          setAllUser(client_data);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const GetAllSpecificOrgInvites = useCallback(async () => {
    if (functionAssignmentId) {
      const invited_user_type = "client_user"
      dispatch(
        userActions.GetAllSpecificOrgInvites(functionAssignmentId, invited_user_type)
      ).then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          let client_data = [];
          for (let key in arrayData) {
            const currentTime = moment();
            let invitedDate = arrayData[key]?.invite_date;
            let invited = moment.duration(currentTime.diff(invitedDate));
            client_data.push({
              inviteId: arrayData[key]?.invite_id,
              email: arrayData[key]?.invite_individual?.email,
              firstName: arrayData[key]?.invite_individual?.first_name,
              lastName: arrayData[key]?.invite_individual?.last_name,
              individualId:
                arrayData[key]?.invite_individual?.individual_id,
              invitedDate: arrayData[key]?.invite_date
                ? invited.asSeconds() < 60
                  ? "Just now"
                  : invited.asMinutes() < 60
                    ? `${Math.floor(invited.asMinutes())} ${invited.asMinutes() === 1 ? "minute" : "minutes"
                    } ago`
                    : invited.asHours() < 24
                      ? `${Math.floor(invited.asHours())} ${invited.asHours() === 1 ? "hour" : "hours"
                      } ago`
                      : invited.asDays() < 2
                        ? "Yesterday"
                        : `${Math.floor(invited.asDays())} ${invited.asDays() === 1 ? "day" : "days"
                        } ago`
                : "",
            });
          }
          setInvitedUser(client_data);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllSpecificOrgUsers();
    GetAllSpecificOrgInvites();
  }, []);


  // to reInvite user
  const ReInviteUser = (e) => {
    const payload = {
      invite_id: e.inviteId,
      trading_name: props?.tradingName,
      first_name: e.firstName,
      last_name: e.lastName,
      email: e.email,
      contact_type_id: contactTypeId,
      invited_user_type: "client_user"
    };
    setButtonDisabled(true)
    props?.onReinviteOrgUser(payload).then((response) => {
      if (response?.success === true) {
        openDialog();
        setButtonDisabled(false)
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
        setButtonDisabled(false)
      }
      else {
        setButtonDisabled(false)
      }
    }).catch((error) => {
      setButtonDisabled(false)
    });
  };


  // to remove Invited user
  const RemoveUserInvite = () => {
    const payload = {
      invite_id: inviteId,
      function_assignment_id: functionAssignmentId
    };
    setButtonDisabled(true)
    props?.onRemoveOrgUserInvitation(payload)
      .then((response) => {
        if (response?.success === true) {
          setButtonDisabled(false)
          GetAllSpecificOrgInvites()
          closeInvitation()
          setInviteId('')
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
          setButtonDisabled(false)
        }
        else {
          setButtonDisabled(false)
        }
      }).catch((error) => {
        setButtonDisabled(false)
      });
  };

  // to remove Invited user
  const RemoveUser = () => {
    const payload = {
      individual_id: deleteUserData?.individualId,
      function_assignment_id: functionAssignmentId,
      individual_org_id: deleteUserData?.individualOrgId,
      user_id: deleteUserData?.userId,
    };
    setButtonDisabled(true);
    dispatch(RemoveOrgUser(payload))
      .then((response) => {
        if (response?.success) {
          GetAllSpecificOrgUsers();
          closeDeleteUser();
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
    
        }
      })
      .catch((error) => {
        console.error('Error removing user:', error);

      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };



  return (
    <React.Fragment>
      <div className="h-500 pr-1">
        <ScrollBar>
          <div className="pr-4 mt-7 pb-6">
            <div className="flex justify-between mb-5">
              <div>
                <h5 className="text-xl text-gray-900 font-semibold mb-1">
                  Users
                </h5>
                <p className="text-gray-600">
                  Manage and update the User’s access.
                </p>
              </div>
              <Button
                onClick={() => {addUserOpen();setButtonDisabled(false)}}
                className="hover-svg w-auto flex items-center px-3 text-xs text-blue-700 font-semibold hover:text-blue-900 focus:text-blue-700"
              >
                Add user
                <svg
                  className="ml-1"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4.167v11.666M4.167 10h11.666"
                    stroke="#5786CC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <THead>
                  <Tr>
                    <Th className="w-1/3">User name</Th>
                    <Th className="w-1/3">Email</Th>
                    <Th>
                      <div className="flex items-center">
                        Last active
                        <Tooltip
                          title="Account Billing contact is responsible for billing and payment on the account."
                          placement="top-start"
                        >
                          <div className="text-center cursor-pointer">
                            <img src={helpCircle} alt="" className="ml-2" />
                          </div>
                        </Tooltip>
                      </div>
                    </Th>
                    <Th></Th>
                  </Tr>
                </THead>
                <TBody>
                  {allUser &&
                    allUser.map((item) => (
                      <Tr key={item?.individualId}>
                        <Td>{item?.name}</Td>
                        <Td>{item?.email}</Td>
                        <Td>{item?.activeDate}</Td>
                        <Td>
                          <span className="flex gap-4 justify-center">
                            <Button onClick={() => deleteUserDialog(item)}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                            <Button onClick={() => openUserEdit(item)}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </span>
                        </Td>
                      </Tr>
                    ))}
                </TBody>
              </Table>
            </div>
            <div className="flex justify-between mb-5 mt-5">
              <h5 className="text-xl text-gray-900 font-semibold">Invited</h5>
            </div>
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <THead>
                  <Tr>
                    <Th className="w-1/3">User name</Th>
                    <Th className="w-1/3">Email</Th>
                    <Th>
                      <div className="flex items-center">
                        Sent
                        <Tooltip
                          title="Account Billing contact is responsible for billing and payment on the account."
                          placement="top-start"
                        >
                          <div className="text-center cursor-pointer">
                            <img src={helpCircle} alt="" className="ml-2" />
                          </div>
                        </Tooltip>
                      </div>
                    </Th>
                    <Th></Th>
                  </Tr>
                </THead>
                <TBody>
                  {invitedUser &&
                    invitedUser.map((item) => (
                      <Tr key={item?.individualId}>
                        <Td>{item?.firstName + " " + item?.lastName}</Td>
                        <Td>{item?.email}</Td>
                        <Td>{item?.invitedDate}</Td>
                        <Td>
                          <span className="flex gap-4 justify-center">
                            <Button disabled={buttonDisabled} onClick={() => openDeleteDialog(item)}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                            <Button disabled={buttonDisabled} onClick={() => ReInviteUser(item)}>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                                  stroke="#475467"
                                  strokeWidth="1.667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </span>
                        </Td>
                      </Tr>
                    ))}
                </TBody>
              </Table>
            </div>
          </div>
        </ScrollBar>
      </div>
      {/*--------- Remove Invitaion -------- */}
      <Dialog
        isOpen={dialogDeleteOpen}
        onClose={closeInvitation}
        onRequestClose={closeInvitation}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Remove Invitation?
          </h4>
          <p className=" text-gray-600 text-center mt-1">
            Removing this invitation will cancel their access to the app.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} className="mr-1.5"
            onClick={() => closeInvitation()}>Cancel</BtnLight>
          <SmallButton disabled={buttonDisabled}
            onClick={() => RemoveUserInvite()}
            className="ml-1.5">
            Confirm
          </SmallButton>
        </div>
      </Dialog>
      {/*--------- Invitaion Sent Confirmation -------- */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <SmallButton onClick={() => onDialogClose()}>Close</SmallButton>
        </div>
      </Dialog>
      {/*--------- Edit User Access-------- */}
      <Dialog
        isOpen={userEdit}
        onClose={closeUserEdit}
        onRequestClose={closeUserEdit}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={userImg} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Edit user access for 
              <span className="ml-2 text-blue-600 font-bold">{nameUser}</span>
            </h5>
            <p className="text-gray-600">Modify user’s access.</p>
          </div>
        </div>
        <EditUser buttonDisabled={buttonDisabled} setButtonDisabled={setButtonDisabled}
          userIdSpecifice={userIdSpecifice} permData={permData} closeUserEdit={closeUserEdit} />
      </Dialog>
      {/*--------- delete User -------- */}
      <Dialog
        isOpen={userDelete}
        onClose={closeDeleteUser}
        onRequestClose={closeDeleteUser}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to delete this user?
          </h4>
          <p className=" text-gray-600 text-center mt-1">
            This action cannot be undone. The user will lose all access to the
            app.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} onClick={() => closeDeleteUser()} className="mr-1.5">Cancel</BtnLight>
          <SmallButton disabled={buttonDisabled} onClick={() => RemoveUser()} className="ml-1.5">
            Confirm
          </SmallButton>
        </div>
      </Dialog>
      {/*--------- Add User -------- */}
      <Dialog
        isOpen={addUser}
        onClose={addUserClose}
        onRequestClose={addUserClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={userImg} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a user
            </h5>
          </div>
        </div>
        <AddUser buttonDisabled={buttonDisabled} setButtonDisabled={setButtonDisabled}
          tradingName={props?.tradingName} addUserClose={addUserClose} />
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
  };
}
const mapDispatchToProps = {
  onReinviteOrgUser: userActions.ReinviteOrgUser,
  onRemoveOrgUserInvitation: userActions.RemoveOrgUserInvitation,
  onRemoveClientUser: userActions.RemoveClientUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);