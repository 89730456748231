import React from 'react';
    
const ClientInput = (props) => {
    const classes = "input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline " + props.className;
    const handleKeyDown = (e) => {
        if (props.type === 'number' && ["e", "E", "+", "-"].includes(e.key)) {
            e.preventDefault();
        } 
    };
    console.log(props.maxLength)
    return (
        <input
            className={classes}
            placeholder={props.placeholder || " "}
            onChange={(e) => props.inputchangehandler(e)}
            value={props.value}
            readOnly={props.readOnly}
            name={props.name}
            type={props.type}
            min={props.type === "date" ? props.min : "0"}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            id={props.id}
            autoComplete="off"
            // maxlength={props.maxLength}
            maxlength={props.maxLength}
            minlength={props.minLength}
            onKeyDown={handleKeyDown}
        />
    );
};

export default ClientInput;
